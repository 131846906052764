




























































































































import Vue from 'vue'
import EquipmentCardBase from './_EquipmentCardBase.vue'

export default Vue.extend({
  name: 'cc-mech-weapon-card',
  components: { EquipmentCardBase },
  props: {
    item: {
      type: Object,
      required: true,
    },
    notes: { type: Boolean },
  },
  data: () => ({
    tab: 0,
  }),
  watch: {
    tab(newval: number) {
      this.item.SetProfileSelection(newval, true)
    },
  },
  mounted() {
    this.tab = this.item.ProfileIndex
  },
})
