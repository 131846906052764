



































import Vue from 'vue'
import { Reserve } from '@/class'

export default Vue.extend({
  name: 'get-focused',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addSkill() {
      this.pilot.ReservesController.AddReserve(
        new Reserve({
          id: 'reserve_skillfocus',
          type: 'Bonus',
          name: 'Skill Focus',
          description: 'Added via the "Get Focused" Downtime Action',
          bonuses: [
            {
              id: 'skill_point',
              val: 1,
            },
          ],
          resource_name: 'Skill Focus',
          resource_cost: '',
          resource_note: '',
          used: false,
          consumable: false,
        })
      )
      this.close()
    },
    close() {
      this.$emit('close')
    },
  },
})
