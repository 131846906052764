





























































import Vue from 'vue'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'

export default Vue.extend({
  name: 'action-dialog-base',
  components: { ActionDetailExpander },
  props: {
    used: { type: Boolean },
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    finished: false,
    overcharge_heat: null,
    resetRoll: true,
  }),
  methods: {
    registerOverchargeRoll(roll) {
      Vue.set(this, 'overcharge_heat', roll)
      Vue.nextTick().then(() => this.$forceUpdate())
    },
    select() {
      this.mech.Pilot.State.OverchargeHeat = parseInt(this.overcharge_heat)
      Vue.nextTick().then(() => this.$emit('use'))
    },
    reset() {
      Vue.nextTick()
        .then(() => this.$emit('undo'))
        .then(() => this.init())
    },
    init(): void {
      this.finished = false
      this.overcharge_heat = null
      this.resetRoll = false
      this.$nextTick(function() {
        this.resetRoll = true
      })
    },
  },
})
