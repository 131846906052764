



















































import Vue from 'vue'
import NoDataBlock from '../../components/NoDataBlock.vue'

export default Vue.extend({
  name: 'special-block',
  components: { NoDataBlock },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addItem(item) {
      this.pilot.AddSpecialEquipment(item)
      this.$refs.specialSelector.hide()
      this.$refs.exoticSelector.hide()
    },
    removeItem(item) {
      this.pilot.RemoveSpecialEquipment(item)
    },
  },
})
