


















































import { ActivationType } from '@/classes/enums'
import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'
import ActionBase from './_actionBase.vue'

export default vueMixins(activePilot).extend({
  name: 'action-button',
  components: { ActionBase },
  props: {
    action: {
      type: Object,
      required: true,
    },
    activations: {
      type: Number,
      required: false,
      default: 2,
    },
    disabled: { type: Boolean },
    unusable: { type: Boolean },
    noAction: { type: Boolean },
  },
  computed: {
    cost() {
      if (this.action.Activation === ActivationType.Quick) return 1
      else if (this.action.Activation === ActivationType.Full) return 2
      return 0
    },
    usable() {
      return this.unusable || this.action.Used || this.activations < this.cost
    },
    displayFreq() {
      return this.action.Frequency.ToString() !== 'Unlimited'
    }
  },
})
