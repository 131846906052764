









import Vue from 'vue'

export default Vue.extend({
  name: 'cc-reserve-card',
  props: {
    item: { type: Object, required: true },
  },
})
