














































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
const semverGte = require('semver/functions/gte')
const semverCoerce = require('semver/functions/coerce')

export default Vue.extend({
  name: 'content-pack-directory-table',
  props: {
    items: {
      type: Array,
      required: true,
    },
    noAuthor: { type: Boolean },
    loading: { type: Boolean },
  },
  computed: {
    tableHeaders() {
      if (this.noAuthor)
        return [
          { text: '', value: 'data-table-expand' },
          { text: 'Download', value: 'website', sortable: false, align: 'center' },
          { text: 'Name', value: 'name' },
          { text: 'Version', value: 'version' },
          { text: 'Cost', value: 'cost' },
        ]
      return [
        { text: '', value: 'data-table-expand' },
        { text: 'Download', value: 'website', sortable: false, align: 'center' },
        { text: 'Name', value: 'name' },
        { text: 'Author', value: 'author' },
        { text: 'Version', value: 'version' },
        { text: 'Cost', value: 'cost' },
      ]
    },
    contentPacks() {
      return getModule(CompendiumStore, this.$store).ContentPacks
    },
  },
  methods: {
    getPack(item) {
      return this.contentPacks.find(
        x =>
          x.Name.toLowerCase() === item.name.toLowerCase() ||
          x.Name.toLowerCase() === item.title.toLowerCase()
      )
    },
    packInstalled(item) {
      return !!this.getPack(item)
    },
    packOutdated(item) {
      const installedPack = this.getPack(item)
      if (!installedPack) return true
      return !semverGte(semverCoerce(installedPack.Version), semverCoerce(item.version))
    },
  },
})
