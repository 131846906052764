



































































import Vue from 'vue'
import ActionCard from '../components/ActionCard.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'action-economy',
  components: { ActionCard },
  data: () => ({
    actionTypes: [
      { action: 'move', icon: '$vuetify.icons.move' },
      { action: 'overcharge', icon: 'cci-overcharge' },
      { action: 'reaction', icon: 'cci-reaction' },
      { action: 'free', icon: 'cci-free' },
    ],
    actions: [],
    pilotActions: [],
    downtimeActions: [],
  }),
  async created() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.actions = await compendium.Actions.filter(
      a => a && !a.IsDowntimeAction && !a.IsPilotAction
    )
    this.pilotActions = await compendium.Actions.filter(a => a && a.IsPilotAction)
    this.downtimeActions = await compendium.Actions.filter(a => a && a.IsDowntimeAction)
  },
})
