

































import Vue from 'vue'

export default Vue.extend({
  name: 'downtime-menu-item',
  props: {
    action: { type: Object, required: true },
    pilot: { type: Object, required: true },
    available: { type: Boolean },
  },
  computed: {
    terse() {
      const txt = this.action.Terse || this.action.Detail
      return txt
    },
  },
})
