






























































import Vue from 'vue'
import { getImagePath, ImageTag } from '@/io/ImageManagement'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import TemplateItem from './components/TemplateItem.vue'
import Templates from '../pregens.json'
import { MechSkills, Mech } from '@/class'
import { mechname } from '@/io/Generators'

export default Vue.extend({
  name: 'templates-page',
  components: { TemplateItem },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    templates() {
      return Templates
    },
    retrogradeLogo() {
      return getImagePath(ImageTag.Misc, 'retrograde_logo.png')
    },
    selectionComplete(): boolean {
      return this.selected !== null
    },
  },
  watch: {
    selectionComplete(bool) {
      if (bool) window.scrollTo(0, document.body.scrollHeight)
    },
  },
  methods: {
    item(type: string, id: string) {
      const compendium = getModule(CompendiumStore, this.$store)
      return compendium.referenceByID(type, id)
    },
    setTemplate() {
      const t = this.selected.build
      this.pilot.MechSkillsController.MechSkills = MechSkills.Deserialize(t.mechSkills)
      this.pilot.SkillsController.ClearSkills()
      t.skills.forEach(s => {
        this.pilot.SkillsController.AddSkill(this.item('Skills', s))
      })
      this.pilot.TalentsController.ClearTalents()
      t.talents.forEach(t => {
        this.pilot.TalentsController.AddTalent(this.item('Talents', t))
      })
      this.pilot.Loadout.Armor = [this.item('PilotGear', t.gear.armor)]
      this.pilot.Loadout.Weapons = t.gear.weapons.map(x => this.item('PilotGear', x))
      this.pilot.Loadout.Gear = t.gear.gear.map(x => this.item('PilotGear', x))

      const m = t.mech
      const mech = new Mech(this.item('Frames', 'mf_standard_pattern_i_everest'), this.pilot)

      mech.Name = mechname()
      mech.MechLoadoutController.ActiveLoadout.Systems = m.systems.map(x =>
        this.item('MechSystems', x)
      )

      mech.MechLoadoutController.ActiveLoadout.AllMounts()
        .find(m => m.Type === 'Main')
        .Slots[0].EquipWeapon(this.item('MechWeapons', m.mounts[0].slots[0]))
      mech.MechLoadoutController.ActiveLoadout.AllMounts()
        .find(m => m.Type === 'Flex')
        .Slots[0].EquipWeapon(this.item('MechWeapons', m.mounts[1].slots[0]))
      mech.MechLoadoutController.ActiveLoadout.AllMounts()
        .find(m => m.Type === 'Flex')
        .Slots[1].EquipWeapon(this.item('MechWeapons', m.mounts[1].slots[1]))
      mech.MechLoadoutController.ActiveLoadout.AllMounts()
        .find(m => m.Type === 'Heavy')
        .Slots[0].EquipWeapon(this.item('MechWeapons', m.mounts[2].slots[0]))

      mech.PortraitController.SetCloudImage(this.selected.image)

      this.pilot.Mechs.forEach(m => {
        this.pilot.RemoveMech(m)
      })
      this.pilot.AddMech(mech)
      this.pilot.ActiveMech = mech

      this.$emit('next')
    },
  },
})
