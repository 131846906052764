














































































































































import Vue from 'vue'
import { Reserve } from '@/class'
export default Vue.extend({
  name: 'damn-drink',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    skillRoll: '',
    details: '',
    losses: ['Your dignity', 'All of your possessions', 'Your memory'],
    kept: '',
    reserve1: '',
    details1: '',
    reserve2: '',
    details2: '',
    loss: '',
    choices: [
      'A good reputation',
      'A friend or connection',
      'A useful item or piece of information',
      'A convenient opportunity',
    ],
  }),
  computed: {
    addDisabled() {
      if (!this.skillRoll) return true
      return (
        (this.skillRoll < 10 && !this.kept) ||
        (this.skillRoll > 10 && this.skillRoll < 20 && (!this.reserve1 || !this.loss)) ||
        (this.skillRoll >= 20 && (!this.reserve1 || !this.reserve2))
      )
    },
  },
  methods: {
    addReserve() {
      if (this.skillRoll < 10) {
        const nr = new Reserve({
          id: 'reserve_damndrink',
          type: 'Resource',
          name: 'A Damn Drink',
          label: '',
          description: '',
          resource_note: '',
          resource_cost: '',
          resource_name: '',
          consumable: true,
          used: false,
        })
        const lossArr = [...this.losses]
        lossArr.splice(
          lossArr.findIndex(x => x === this.kept),
          1
        )
        nr.ResourceCost = `You've lost ${lossArr[0].toLowerCase()}, as well as ${lossArr[1].toLowerCase()}`
        this.pilot.ReservesController.AddReserve(nr)
      } else if (this.skillRoll < 20) {
        const nr = new Reserve({
          id: 'reserve_damndrink',
          type: 'Resource',
          name: this.reserve1,
          label: '',
          description: '',
          resource_note: '',
          resource_cost: '',
          resource_name: '',
          consumable: true,
          used: false,
        })
        nr.Note = this.details1
        nr.ResourceCost = `You've lost ${this.loss.toLowerCase()}`
        this.pilot.ReservesController.AddReserve(nr)
      } else {
        const nr = new Reserve({
          id: 'reserve_damndrink',
          type: 'Resource',
          name: this.reserve1,
          label: '',
          description: '',
          resource_note: '',
          resource_cost: '',
          resource_name: '',
          consumable: true,
          used: false,
        })
        nr.Note = this.details1
        this.pilot.ReservesController.AddReserve(nr)

        const nr2 = new Reserve({
          id: 'reserve_damndrink',
          type: 'Resource',
          name: this.reserve2,
          label: '',
          description: '',
          resource_note: '',
          resource_cost: '',
          resource_name: '',
          consumable: true,
          used: false,
        })
        nr2.Note = this.details2
        this.pilot.ReservesController.AddReserve(nr2)
      }
      this.close()
    },
    close() {
      this.skillRoll = ''
      this.details = ''
      this.kept = ''
      this.reserve1 = ''
      this.details1 = ''
      this.reserve2 = ''
      this.details2 = ''
      this.loss = ''
      this.$emit('close')
    },
  },
})
