



























































































import Vue from 'vue'
import { getImagePath, ImageTag } from '@/io/ImageManagement'
import map from '@/assets/artistmap.json'
import path from 'path'

export default Vue.extend({
  name: 'frame-gallery-modal',
  props: {
    frame: { type: Object, required: true },
  },
  data: () => ({
    dialog: false,
    selected: null,
  }),
  computed: {
    artist() {
      if (!this.selected) return null
      const basename = path.basename(this.selected, path.extname(this.selected))
      const artist = map.find(x => x.images.some(y => y.img === basename))
      if (!artist) return null
      const image = artist.images.find(x => x.img === basename)
      return {
        imgName: image.name,
        name: artist.artist,
        logo: artist.logo ? getImagePath(ImageTag.Misc, artist.logo) : '',
        website: artist.website || null,
        twitter: artist.twitter || null,
      }
    },
  },
  created() {
    this.selected = this.frame.DefaultImage
  },
  methods: {
    selectImg(a) {
      this.selected = this.imgPath(a.tag, a.src)
      this.$emit('set-img', this.imgPath(a.tag, a.src))
    },
    imgPath(tag: ImageTag, src: string) {
      return getImagePath(tag, src)
    },
  },
})
