














































































import Vue from 'vue'

export default Vue.extend({
  name: 'overview-page',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
    cbEligible: {
      type: Boolean,
    },
  },
})
