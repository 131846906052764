






























import Vue from 'vue'
export default Vue.extend({
  name: 'mech-select-button',
  props: {
    mechs: {
      type: Array,
      required: true,
    },
  },
})
