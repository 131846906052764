























































































































































































import Vue from 'vue'
import PilotCard from './components/PilotCard.vue'
import PilotListItem from './components/PilotListItem.vue'
import ImportDialog from './components/ImportDialog.vue'
import MassDelete from './components/MassDelete.vue'
import { getModule } from 'vuex-module-decorators'
import { UserStore, PilotManagementStore } from '@/store'
import { Pilot } from '@/class'
import { UserProfile } from '@/user'
import draggable from 'vuedraggable'
import { teamName } from '@/io/Generators'
import { PilotGroup } from '../store'

export default Vue.extend({
  name: 'roster-view',
  components: { ImportDialog, PilotListItem, PilotCard, MassDelete, draggable },
  data: () => ({
    sortParams: null,
    drag: false,
    newGroupMenu: false,
    newGroupName: '',
    preventDnd: true,
    groups: [],
  }),
  watch: {
    plength() {
      this.buildGroups()
    },
  },
  computed: {
    pilotStore() {
      const mod = getModule(PilotManagementStore, this.$store)
      return mod
    },
    pilotCardType(): string {
      switch (this.getRosterView) {
        case 'cards':
        case 'small-cards':
          return 'pilot-card'
        case 'list':
        default:
          return 'pilot-list-item'
      }
    },
    profile(): UserProfile {
      const store = getModule(UserStore, this.$store)
      return store.UserProfile
    },
    pilots() {
      return this.pilotStore.Pilots
    },
    plength() {
      return this.pilots.length
    },
    pilotGroups() {
      return this.pilotStore.pilotGroups
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
        scrollSensitivity: 200,
        forceFallback: true,
        fallbackTolerance: 3,
      }
    },
    dragClick() {
      return this.drag ? 'click' : null
    },
    isTouch() {
      if ('ontouchstart' in document.documentElement) {
        return true
      } else {
        return false
      }
    },
    getRosterView() {
      if (!this.profile || !this.profile.GetView) return 'list'
      return this.profile.GetView('roster')
    },
  },
  created() {
    this.preventDnd = this.isTouch
  },
  mounted() {
    this.buildGroups()
  },
  methods: {
    buildGroups() {
      let groups = [
        ...this.pilotStore.PilotGroups.filter(
          x => x.name && x.name !== '' && x.name.toLowerCase() !== 'ungrouped'
        ),
      ]
      groups.forEach(g => {
        g.pilotIDs = this.pilots
          .filter(p => p.GroupController.Group === g.name)
          .sort((p1, p2) => p1.GroupController.SortIndex - p2.GroupController.SortIndex)
          .map(p => p.ID)
      })
      groups.push({
        name: '',
        pilotIDs: this.pilots
          .sort((p1, p2) => p1.GroupController.SortIndex - p2.GroupController.SortIndex)
          .map(p => p.ID)
          .filter(id => !groups.flatMap(g => g.pilotIDs).includes(id)),
        hidden: false,
      })
      this.groups = groups
    },

    toggleHidden(g: PilotGroup) {
      g.hidden = !g.hidden
    },
    showAll() {
      this.groups.forEach(g => (g.hidden = false))
    },
    hideAll() {
      this.groups.forEach(g => (g.hidden = true))
    },
    onSort(sortParams: any[]) {
      this.sortParams = sortParams
    },
    dragOff() {
      setTimeout(() => {
        this.drag = false
      }, 50)
    },
    addNewGroup() {
      this.groups.push({
        name: this.newGroupName,
        pilotIDs: [],
        hidden: false,
      })
      this.newGroupName = ''
      this.newGroupMenu = false
    },
    getPilotFromId(id: string): Pilot {
      return this.pilots.find(p => p.ID === id)
    },
    movePilot(groupName, event) {
      if (event.added || event.moved) {
        const pilotId = event.added ? event.added.element : event.moved.element
        const pilot = this.getPilotFromId(pilotId)
        pilot.GroupController.Group = groupName

        this.groups.forEach(g => {
          g.pilotIDs.forEach((id, i) => {
            const p = this.getPilotFromId(id)
            p.GroupController.SortIndex = i
          })
        })
      }
      this.pilotStore.moveGroup(this.groups)
    },
    deleteGroup(g) {
      this.pilotStore.deleteGroup(g)
      this.buildGroups()
    },
    setGroupName(g, newName) {
      this.pilotStore.setGroupName({ g: g, newName: newName })
    },
    randomName() {
      this.newGroupName = teamName()
    },
  },
})
