










































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'cc-stepper-content' })
export default class CCStepperContent extends Vue {
  @Prop({ type: Boolean, required: false })
  readonly noConfirm?: boolean
  @Prop({ type: Boolean, required: false })
  readonly back?: boolean
  @Prop({ type: Boolean, required: false })
  readonly mandatory?: boolean

  @Prop({ type: Boolean, required: true })
  readonly complete!: boolean
  @Prop({ type: String, required: true })
  readonly exit!: string
}
