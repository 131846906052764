/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_appsync_graphqlEndpoint:
    'https://hmhcmquzenfaxpearfzp3f3dlu.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-nmaqhbb62zabjhctesiydgfuvu',
  aws_cloud_logic_custom: [
    {
      name: 'createpatreonsnowflake',
      endpoint: 'https://1eh3faw192.execute-api.us-east-1.amazonaws.com/prod',
      region: 'us-east-1',
    },
  ],
  aws_cognito_identity_pool_id: 'us-east-1:61808e08-a277-4e9c-b291-f40de3f94ef7',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_AdX2iSx8s',
  aws_user_pools_web_client_id: '5v4pvpbr6mbcgfj0cll14trpkk',
  oauth: {},
  aws_user_files_s3_bucket: 'cc-user-storageprod-prod',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_dynamodb_all_tables_region: 'us-east-1',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'patreonsnowflakes-prod',
      region: 'us-east-1',
    },
    {
      tableName: 'userpatreontokens-prod',
      region: 'us-east-1',
    },
  ],
}

export { awsmobile }
