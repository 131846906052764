







































































































import Vue from 'vue'
import NpcChip from '../../../encounter/components/NpcChip.vue'
import NpcSelector from '../../../encounter/components/NpcSelector.vue'
import PilotSelector from '../PilotSelector.vue'
import { Pilot, Npc, EncounterSide } from '@/class'

export default Vue.extend({
  name: 'reinforcements-modal',
  components: { NpcChip, PilotSelector, NpcSelector },
  props: {
    mission: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addPilot(pilot: Pilot) {
      this.mission.AddPilot(pilot)
      this.$refs.pilotDialog.hide()
    },
    addNpc(event: { npc: Npc; side: EncounterSide }) {
      this.mission.AddActiveReinforcement(event.npc, event.side)
      this.$refs.npcDialog.hide()
    },
  },
})
