























































































import { WeaponSize } from '@/class'
import Vue from 'vue'
import WeaponAttack from '../../components/_WeaponAttack.vue'

export default Vue.extend({
  name: 'skirmish-dialog',
  components: { WeaponAttack },
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
    mech: {
      type: Object,
      required: true,
    },
    mount: {
      type: Object,
      required: false,
      default: null,
    },
    overwatch: {
      type: Boolean,
    },
    returnFire: {
      type: Boolean,
    },
  },
  data: () => ({
    dialog: false,
    confirmedFree: false,
  }),
  methods: {
    hasAux(mount, primary) {
      const auxes = mount.Weapons.filter(x => x.Size === WeaponSize.Aux && x.Loaded)
      if (!auxes.length) return false
      const unusedAux = auxes.filter(x => x !== primary)
      if (!unusedAux.length) return false
      const candidate = unusedAux[0]
      if (this.item === candidate) return false
      return !this.returnFire && (candidate || false)
    },
    confirmAttack(free) {
      this.confirmedFree = free
      this.$emit('confirm', free)
    },
    reset() {
      this.$refs.main.reset()
      if (this.hasAux && this.$refs.aux) this.$refs.aux.reset()
    },
    init() {
      this.$refs.main.init()
    },
    confirm(): void {
      if (this.confirmedFree) this.reset()
      this.confirmedFree = false
      this.dialog = false
      this.$emit('close')
      this.$refs.main.init()
    },
    show(): void {
      this.dialog = true
    },
    hide(): void {
      if (this.confirmedFree) this.reset()
      this.confirmedFree = false
      this.dialog = false
      this.$emit('close')
      this.$refs.main.init()
    },
  },
})
