






































































import Vue from 'vue'
import { FrameGalleryModal, FrameStatblock, FrameCoreSystemPanel } from './frame'
import { glossary } from '@massif/lancer-data'

export default Vue.extend({
  name: 'cc-frame-card',
  components: { FrameGalleryModal, FrameStatblock, FrameCoreSystemPanel },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    glossary(name: string) {
      return glossary.find(x => x.name.toLowerCase() === name.toLowerCase()).description
    },

    get_mount_tooltip(mount_type: string) {
      const mount_tooltips = {
        Heavy: 'Holds one <b>HEAVY</b>, <b>MAIN</b>, or <b>AUXILIARY</b> weapon',
        Main: 'Holds one <b>MAIN</b> or <b>AUXILIARY</b> weapon',
        'Aux/Aux': 'Holds up to two <b>AUXILIARY</b> weapons',
        Aux: 'Holds one <b>AUXILIARY</b> weapon',
        'Main/Aux':
          'Holds one <b>MAIN</b> weapon and one <b>AUXILIARY</b> weapon, or two <b>AUXILIARY</b> weapons',
        Flex: 'Holds either one <b>MAIN</b> weapon or up to two <b>AUXILIARY</b> weapons',
      }
      if (mount_type in mount_tooltips) {
        return mount_tooltips[mount_type]
      }
      return 'Error: Unknown Mount Type'
    },
  },
})
