










































































































































import Vue from 'vue'
import MechNav from './components/MechNav.vue'
import CoreItem from './components/CoreItem.vue'
import OperatorNotes from './sections/OperatorNotes.vue'
import LicenseRequirementBlock from './sections/license_requirements/index.vue'
import TraitBlock from './sections/traits/index.vue'
import AttributesBlock from './sections/attributes/index.vue'
import DeleteMechDialog from '../hangar/components/DeleteMechDialog.vue'
import { Pilot, Mech } from '@/class'

export default Vue.extend({
  name: 'mech-sheet',
  components: {
    MechNav,
    LicenseRequirementBlock,
    OperatorNotes,
    TraitBlock,
    AttributesBlock,
    CoreItem,
    DeleteMechDialog,
  },
  props: {
    pilotID: {
      type: String,
      required: true,
    },
    mechID: {
      type: String,
      required: true,
    },
  },
  computed: {
    small() {
      return this.$vuetify.breakpoint.smAndDown
    },
    pilot(): Pilot {
      return this.$store.state.management.Pilots.find(p => p.ID === this.pilotID)
    },
    mech(): Mech {
      return this.pilot.Mechs.find((m: Mech) => m.ID === this.mechID)
    },
    color() {
      return this.mech.Frame.Manufacturer.GetColor(this.$vuetify.theme.dark)
    },
  },
  methods: {
    deleteMech() {
      this.$router.push({ name: 'mech_hangar' })
      this.pilot.RemoveMech(this.mech)
    },
  },
})
