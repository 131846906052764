
















































import Vue from 'vue'

export default Vue.extend({
  name: 'compendium-split-view',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    selectedItem() {
      return this.items.find(x => x.ID === this.selected)
    },
  },
})
