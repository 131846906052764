



















































import Vue from 'vue'
import { Pilot } from '@/class'
import { CustomSkill } from '@/class'
export default Vue.extend({
  name: 'add-custom-skill',
  props: {
    pilot: Pilot,
  },
  data: () => ({
    newSkill: '',
    newDesc: '',
    newDetail: '',
  }),
  computed: {
    canAdd(): boolean {
      const custSkill = new CustomSkill(this.newSkill, this.newDesc, this.newDetail)
      return this.pilot.SkillsController.CanAddSkill(custSkill)
    },
  },
  methods: {
    addSkill() {
      this.$emit('add-custom', {
        skill: this.newSkill,
        description: this.newDesc,
        detail: this.newDetail,
      })
      this.newSkill = ''
      this.newDesc = ''
      this.newDetail = ''
    },
  },
})
