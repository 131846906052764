














































import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import Vue from 'vue'

export default Vue.extend({
  name: 'manufacturers',
  data: () => ({
    tabModel: 0,
  }),
  computed: {
    manufacturers() {
      return getModule(CompendiumStore, this.$store).Manufacturers.filter(x => !x.IsHidden)
    },
  },
})
