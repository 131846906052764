var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('p',{staticClass:"text-center body-text",domProps:{"innerHTML":_vm._s(
        'You try and stave off some reckoning, extend your window of opportunity, or merely buy more time and breathing room for you and your group to act. You might be trying to dodge some heat, survive stranded in the wilderness, or cause a distraction so another plan can go off. <br /> You can use that distraction or bought time as <strong>reserves</strong> for the next mission.'
      )}}),_c('v-divider',{staticClass:"mb-2"}),_c('div',{staticClass:"pt-2 heading h3 text-center"},[_vm._v(" Roll "),_c('v-icon',{attrs:{"large":"","color":"accent"}},[_vm._v("mdi-dice-d20")]),_vm._v("  and add any relevant Skill Trigger bonuses, modifiers, or accuracy ")],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"type":"number","label":"Roll Result","outlined":"","dense":"","hide-details":"","append-outer-icon":"mdi-plus-circle-outline","prepend-icon":"mdi-minus-circle-outline"},on:{"click:append-outer":function($event){_vm.skillRoll++},"click:prepend":function($event){_vm.skillRoll > 1 ? _vm.skillRoll-- : ''}},model:{value:(_vm.skillRoll),callback:function ($$v) {_vm.skillRoll=$$v},expression:"skillRoll"}})],1)],1),_c('v-slide-y-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.skillRoll),expression:"skillRoll"}],staticClass:"text-center flavor-text",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[(_vm.skillRoll < 10)?_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'You can buy only a little time, and only if drastic measures are taken <em>right now.</em> Otherwise, whatever you\'re trying to stave off catches up to you.'
            )}}):(_vm.skillRoll < 20)?_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'You can buy enough time, but the situation becomes precarious or desperate. Next time you get this result with the same situation, treat it as a 9 or lower.'
            )}}):_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'You buy enough time as you need for now, until the next mission. If you\'ve already gotten this result, it becomes a 10-19 for the same situation next time.'
            )}}),_c('v-card',{staticClass:"ml-5 mr-5",attrs:{"color":"panel","flat":"","tile":""}},[_c('v-toolbar',{attrs:{"dark":"","dense":"","color":"action--downtime"}},[_c('v-toolbar-title',{staticClass:"heading h2"},[_vm._v("Bought Time")])],1),_c('v-card-text',[_c('v-textarea',{attrs:{"color":"accent","auto-grow":"","rows":"1","label":"Details","filled":""},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}})],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"large":"","tile":"","color":"primary","disabled":_vm.skillRoll === '' || _vm.details === ''},on:{"click":function($event){return _vm.addReserve()}}},[_vm._v(" add reserve ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }