



































































































import Vue from 'vue'

export default Vue.extend({
  name: 'pilot-card',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
    },
    dragging: {
      type: Boolean,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    minWidth() {
      if (this.mobile) {
        return this.small ? '18vw' : '40vw'
      }
      return this.small ? '10vw' : '20vw'
    },
  },
  methods: {
    toPilotSheet() {
      this.$router.push(`pilot/${this.pilot.ID}`)
    },
  },
})
