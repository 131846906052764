

































import Vue from 'vue'
import _ from 'lodash'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import CompendiumCard from './components/CompendiumCard.vue'

export default Vue.extend({
  name: 'selector-cards-view',
  components: { CompendiumCard },
  props: {
    items: {
      type: Array,
      required: true,
    },
    equipmentAdd: { type: Boolean },
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    compendium() {
      return getModule(CompendiumStore, this.$store)
    },
    sources() {
      if (this.equipmentAdd) {
        return ['']
      }
      if (!this.items.some(x => !x.Source)) {
        const sources = _.uniq(this.items.map(x => x.Source))
        return sources.map((x: string) =>
          this.compendium.Manufacturers.find(y => y.ID === x.toUpperCase())
        )
      }
      return ['']
    },
  },
  methods: {
    itemsBySource(s) {
      if (!s) return this.items
      if (this.equipmentAdd) return this.items.filter(x => x.ItemType === s)
      return this.items.filter(x => x.Source === s)
    },
  },
})
