





































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component({ name: 'cc-solo-dialog' })
export default class CCSoloDialog extends Vue {
  @Prop({ type: String, required: false, default: 'primary' })
  readonly color: string
  @Prop({ type: String, required: false, default: '' })
  readonly icon: string

  @Prop({ type: Boolean, required: false })
  readonly small?: boolean
  @Prop({ type: Boolean, required: false })
  readonly large?: boolean

  @Prop({ type: Boolean, required: false })
  readonly fullscreen?: boolean

  @Prop({ type: Boolean, required: false })
  readonly noConfirm?: boolean
  @Prop({ type: Boolean, required: false })
  readonly noActions?: boolean
  @Prop({ type: Boolean, required: false })
  readonly noPad?: boolean
  @Prop({ type: Boolean, required: false })
  readonly noTitleClip?: boolean

  @Prop({ type: String, required: false })
  readonly title?: string

  dialog = false
  @Emit()
  confirm(): void {
    this.dialog = false
  }
  show(): void {
    this.dialog = true
  }
  hide(): void {
    this.$emit('close')
    this.dialog = false
  }
}
