














import Vue from 'vue'

export default Vue.extend({
  name: 'delete-pilot-dialog',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    show() {
      this.dialog = true
    },
    hide() {
      this.dialog = false
    },
    remove() {
      this.$emit('delete')
      this.hide()
    },
  },
})
