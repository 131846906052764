








































import Vue from 'vue'
export default Vue.extend({
  name: 'cascade-check',
  props: {
    mech: { type: Object, required: true },
  },
  data: () => ({
    checked: [],
  }),
  computed: {
    AiSystems() {
      return this.mech.MechLoadoutController.ActiveLoadout.Equipment.filter(
        x => x.IsAI && !x.NoCascade && !x.Destroyed
      )
    },
  },
  methods: {
    checkCascade(roll, index) {
      this.checked.push(index)
      if (roll === 1) this.AiSystems[index].IsCascading = true
      else this.AiSystems[index].IsCascading = false
    },
  },
})
