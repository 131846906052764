






























import { Vue, Component, Prop} from 'vue-property-decorator'
import { Mech, Pilot, Statblock } from '@/class'
import CCSoloDialog from '@/ui/components/CCSoloDialog.vue'

@Component({ name: 'statblock-dialog' })
export default class StatblockDialog extends Vue {
  @Prop({type: Object, required: true})
  readonly pilot: Pilot
  @Prop({type: String, required: false})
  readonly mechID: string

  selected_mech = null
  discordEmoji = false
  genRadios = 'mechBuild'

  mounted() {
    if (this.mechSelect == null) {this.genRadios='pilotBuild'} 
  }

  get defaultMechID(): string {
    if (this.$route.name=="mech-sheet") {
      return this.mechID
    }
    else return this.pilot.ActiveMech?.ID ?? this.pilot.Mechs[this.pilot.Mechs.length-1]?.ID ?? ''
  }

  get mechSelect(): string {
    return this.selected_mech ?? this.defaultMechID
  }

  set mechSelect(mech_id: string) {
    this.selected_mech = mech_id
  }

  get mech(): Mech {
    return this.mechSelect ? this.pilot.Mechs.find(x => x.ID === this.mechSelect) : null
  }

  get statblock(): string {    
    if (this.genRadios != "mechBuild") {
      return Statblock.Generate(this.pilot, this.mech, this.discordEmoji, this.genRadios)
    }
    else return Statblock.GenerateBuildSummary(this.pilot, this.mech, this.discordEmoji)  
  }

  $refs!: {
    dialog: CCSoloDialog
  }

  clearSelected() {
    this.selected_mech = null
  }

  show() {
    this.$refs.dialog.show()
  }
  hide() {
    this.$refs.dialog.hide() 
  }
  copy() {
    navigator.clipboard
      .writeText(this.statblock)
      .then(() => Vue.prototype.$notify('Stat block copied to clipboard.', 'confirmation'))
      .catch(() => Vue.prototype.$notifyError('Unable to copy stat block'))
  }
}
