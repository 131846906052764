import { render, staticRenderFns } from "./CompendiumCard.vue?vue&type=template&id=c98f7db2&"
import script from "./CompendiumCard.vue?vue&type=script&lang=ts&"
export * from "./CompendiumCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VLazy } from 'vuetify/lib/components/VLazy';
installComponents(component, {VCard,VCol,VHover,VLazy})
