










































































































































import Vue from 'vue'
import SkirmishDialog from '../dialogs/action/_SelSkirmishDialog.vue'
import BarrageDialog from '../dialogs/action/_SelBarrageDialog.vue'
import ShBarrageDialog from '../dialogs/action/_SelSHBarrageDialog.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { WeaponSize } from '@/class'

export default Vue.extend({
  name: 'weapon-activators',
  components: { SkirmishDialog, BarrageDialog, ShBarrageDialog },
  props: {
    item: {
      type: Object,
      required: true,
    },
    mech: {
      type: Object,
      required: true,
    },
    mount: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    regularConfirmations: 0,
  }),
  computed: {
    state() {
      return this.mech.Pilot.State
    },
    compendium() {
      return getModule(CompendiumStore, this.$store)
    },
    barrageCount() {
      return this.state.BarrageSelections.length
    },
    skirmishHelp() {
      return this.compendium.Actions.find(x => x.ID === 'act_skirmish').Detail
    },
    barrageHelp() {
      return this.compendium.Actions.find(x => x.ID === 'act_barrage').Detail
    },
    barrageToggle() {
      return this.state.BarrageSelections.some(x => x === this.item)
    },
    soloBarrageDisabled() {
      if (this.mech.IsStunned) return true
      if (this.item.IsOrdnance && !this.state.IsProtocolAvailable) return true
      if (!this.item.CanBarrage) return true
      if (this.mech.Pilot.State.Actions < 2) return true
      if (this.item.IsLoading && !this.item.Loaded) return true
      return !!this.barrageCount
    },
    barrageDisabled() {
      if (this.mech.IsStunned) return true
      if (!this.item.CanBarrage) return true
      if (this.mech.Pilot.State.Actions < 2) return true
      if (this.item.Size === WeaponSize.Superheavy) return this.barrageCount > 0
      if (this.item.IsOrdnance && !this.state.IsProtocolAvailable) return true
      if (this.item.IsLoading && !this.item.Loaded) return true
      return !this.barrageToggle && this.barrageCount === 2
    },
    canSkirmish() {
      if (this.item.IsLoading && !this.item.Loaded) return false
      if (this.item.IsOrdnance && !this.state.IsProtocolAvailable) return false
      if (!this.item.CanSkirmish) return false
      return this.state.IsSkirmishAvailable
    },
  },
  methods: {
    setBarrage(item, mount) {
      if (this.item.Size === WeaponSize.Superheavy) {
        this.state.SelectShBarrage(item, mount)
        this.$refs.sh_b_dialog.show()
      } else if (this.barrageCount < 2 && !this.barrageToggle) {
        this.state.SelectBarrage(item, mount)
        if (this.barrageCount === 2) {
          this.$refs.b_dialog.show()
        }
      } else {
        this.state.RemoveBarrage(item, mount)
      }
    },
    regularConfirm() {
      this.regularConfirmations += 1
      if (this.regularConfirmations === 2) {
        this.state.RegisterBarrage()
        this.regularConfirmations = 0
      }
    },
    shConfirm(free) {
      this.state.RegisterBarrage(free)
    },
    completeSkirmish(free) {
      this.state.RegisterSkirmish(free)
    },
  },
})
