






























































































































import Vue from "vue";
import csvConverter from "./csvConverter";
import _ from "lodash";
import credits from "./credits.json";
import DevBadge from "./SupporterBadges/Dev.vue";
import SpecialThanks from "./SupporterBadges/SpecialThanks.vue";

export default Vue.extend({
  name: "credits",
  components: { DevBadge, SpecialThanks },
  data: () => ({
    patronsUrl: "https://compcon-text-assets.s3.amazonaws.com/patrons.csv",
    credits: credits,
    patrons: [],
    tiers: ["MONIST", "NHP", "Lancer", "Cosmopolitan", "Diasporan"],
    cols: [12, 6, 4, 4, 4],
    loading: true,
  }),
  async mounted() {
    await fetch(this.patronsUrl, {
      method: "GET",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.text())
      .then((content) => {
        const patrons = csvConverter.toJson(content);

        this.patrons = _.groupBy(
          _.orderBy(patrons, "Patron Status", "asc"),
          "Tier"
        );
      })
      .catch((err) => {
        console.error(
          "There was an issue downloading the latest welcome message.",
          err
        );
      });

    this.loading = false;
  },
  methods: {
    isActive(p) {
      return p["Patron Status"].toLowerCase() === "active patron";
    },
  },
});
