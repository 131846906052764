








































































import Vue from "vue";
import { Rules } from "@/class";

export default Vue.extend({
  name: "level-edit-dialog",
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    alert: true,
    newLevel: 0,
    levels: Array.from(Array(Rules.MaxPilotLevel + 1).keys()),
  }),
  methods: {
    show() {
      this.$refs.dialog.show();
      this.alert = true;
      this.newLevel = this.pilot.Level;
    },
    hide() {
      this.$refs.dialog.hide();
    },
    setLevel() {
      this.pilot.Level = parseInt(this.newLevel) || 0;
      this.hide();
    },
  },
});
