






























































































































































































import Vue from 'vue'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'

export default Vue.extend({
  name: 'self-destruct-dialog',
  components: { ActionDetailExpander },
  props: {
    synergyLocation: { type: [String, Array], required: false, default: () => [] },
    log: { type: Array, required: false, default: () => [] },
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    actionCost: false,
    actionFree: false,
    expanded: false,
    finished: false,
    or1: false,
    or2: false,
    or3: false,
  }),
  computed: {
    state() {
      return this.mech.Pilot.State
    },
  },
  methods: {
    select(action) {
      return !action
    },
    start() {
      this.finished = true
      this.state.CommitAction(this.action, this.actionFree)
    },
    reset() {
      this.state.UndoAction(this.action)
      this.init()
    },
    init() {
      this.actionCost = false
      this.actionFree = false
      this.finished = false
      this.or1 = false
      this.or2 = false
      this.or3 = false
      this.timer = 0
    },
    selfDestruct() {
      this.state.SelfDestruct()
      this.$emit('hide')
    },
  },
})
