



























import Vue from 'vue'
import ItemMenu from './cards/_ItemMenu.vue'

export default Vue.extend({
  name: 'cc-npc-item-chip',
  components: { ItemMenu },
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
})
