











































import Vue from 'vue'
import { NpcItem } from '@/class'
export default Vue.extend({
  name: 'recharge-menu',
  props: {
    npc: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    menu: false,
    roll: 0,
  }),
  computed: {
    chargables() {
      return this.npc.Items.filter((x: NpcItem) => x.IsChargable && !x.IsCharged)
    },
    recharged() {
      if (!this.roll) return []
      return this.chargables.filter((x: NpcItem) => x.ChargeRoll <= this.roll)
    },
  },
  methods: {
    rollDie() {
      this.roll = Math.floor(Math.random() * 6) + 1
    },
    commit() {
      this.recharged.forEach((e: NpcItem) => {
        e.IsCharged = true
      })
      this.roll = 0
      this.menu = false
    },
  },
})
