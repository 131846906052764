



































import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'
import DeployableItem from '../components/DeployableItem.vue'

export default vueMixins(activePilot).extend({
  name: 'deployed-block',
  components: { DeployableItem },
  data: () => ({
    showDeployed: true,
  }),
})
