










































































































































import Vue from 'vue'
import Single from './blanks/Single.vue'

export default Vue.extend({
  name: 'pilot-blank-view',
  components: { Single },
})
