











































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Damage, DamageType } from '@/class'

@Component({ name: 'cc-damage-element' })
export default class CCDamageElement extends Vue {
  @Prop({
    type: Array,
    required: true,
    validator: (prop: Damage[]) => prop.every(dmg => dmg instanceof Damage),
  })
  readonly damage: Damage[]

  @Prop({ type: [String, null], required: false })
  readonly typeOverride: DamageType

  @Prop({ type: Boolean, required: false })
  readonly small: boolean

  @Prop({ type: Boolean, required: false })
  readonly added: boolean

  Help(d: Damage): string {
    switch (d.Type.toLowerCase()) {
      case 'heat':
        return `This equipment deals ${d.Value} ${d.Type} Damage<br><div class="overline subtle--text mb-n2 ">HEAT:</div>Heat is a special type of harm that doesn’t count as damage and ignores ARMOR, although it can be affected by RESISTANCE. It represents damage to a mech's internal systems and reactor. It's most often inflicted by electronic warfare, but is often generated by a mech’s own systems. When a mech reaches its HEAT CAP, any additional Heat causes it to overheat. If a character without a HEAT CAP (such as BIOLOGICAL characters and some DRONES) would take Heat, they instead take an equivalent amount of Energy damage.`
        break
      case 'burn':
        return `This equipment deals ${d.Value} ${d.Type} Damage<br><div class="overline subtle--text mb-n2 ">BURN:</div>Some weapons deal Burn (damage over time). Burn might represent flames, searing plasma, acid or something more insidious, like a swarm of greywash nanites. When characters take Burn, it has two effects: first, they immediately take Burn damage, ignoring ARMOR, and then they record Burn they just took. At the end of their turn, characters with Burn marked must roll an ENGINEERING check. On a success, they clear all burn currently marked; otherwise, they take Burn damage equal to the amount of Burn currently marked. Burn from additional sources adds to the total marked Burn.`
      default:
        return `This equipment deals ${d.Value} ${d.Type} Damage`
    }
  }
}
