














































































































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore, PilotManagementStore, NpcStore } from '@/store'
import { Pilot, ContentPack } from '@/class'
import AutoUpdater from './components/AutoUpdater.vue'

export default Vue.extend({
  name: 'missing-content-pane',
  components: { AutoUpdater },
  data: () => ({
    loading: false,
  }),
  computed: {
    missing() {
      return getModule(CompendiumStore, this.$store).MissingContent
    },
    missingPacks(): ContentPack[] {
      return getModule(CompendiumStore, this.$store).ContentPacks.filter(
        x => x.Missing
      ) as ContentPack[]
    },
    missingLength() {
      if (!this.missing && !this.missingPacks) return 0
      return (
        (this.missing.pilots?.length || 0) +
        (this.missing.npcs?.length || 0) +
        this.missingPacks.length
      )
    },
  },
  methods: {
    getMissingDependencies(pack: ContentPack) {
      return pack.Dependencies.map(dep => {
        return {
          name: dep.name,
          version: dep.version.includes('=')
            ? dep.version.replace('=', '')
            : dep.version + ' or later',
          link: dep.link,
          installed: getModule(CompendiumStore, this.$store).packAlreadyInstalled(
            dep.name,
            dep.version
          ),
        }
      }).filter(x => !x.installed)
    },
    notActive(itemBrews) {
      return getModule(CompendiumStore, this.$store)
        .ContentPacks.filter(x => itemBrews.some(y => y.LcpId === x.ID))
        .map(p => `${p.Name} @ ${p.Version}`)
    },
    notInstalled(itemBrews) {
      return itemBrews.filter(
        x => !getModule(CompendiumStore, this.$store).ContentPacks.some(y => y.ID === x.LcpId)
      )
    },
    deleteItem(item, key) {
      if (key === 'pilots') {
        getModule(PilotManagementStore, this.$store).deleteMissingPilot(item)
      } else if (key === 'npcs') {
        getModule(NpcStore, this.$store).deleteMissingNpc(item)
      }
    },
    forceItem(item, key) {
      if (key === 'pilots') {
        Pilot.AddNew(item)
        getModule(PilotManagementStore, this.$store).deleteMissingPilot(item)
      } else if (key === 'npcs') {
        getModule(NpcStore, this.$store).deleteMissingNpc(item)
      }
    },
    forceUpdate(item, key) {
      item.brews.splice(0, item.brews.length)
      this.forceItem(item, key)
    },
  },
})
