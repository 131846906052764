

































import _ from 'lodash'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'
import ItemSelectorRow from '../../components/_ItemSelectorRow.vue'

import Vue from 'vue'

export default Vue.extend({
  name: 'full-activation-dialog',
  components: { ActionDetailExpander, ItemSelectorRow },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    state() {
      return this.mech.Pilot.State
    },
    actions() {
      const availableActions = this.state
        .ItemActions('Full')
        .filter(x => this.state.AvailableActions.includes(x.ID))
      return _.groupBy(availableActions, 'Origin')
    },
  },
  methods: {
    activate(action) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      this.selected = action
      Vue.nextTick()
        .then(() => (self.selected = action))
        .then(() => Vue.nextTick().then(() => self.$refs.i_dialog.show()))
    },
    init() {
      this.selected = null
    },
  },
})
