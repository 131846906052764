












































import Vue from 'vue'
import SectionEditIcon from '../../components/SectionEditIcon.vue'
import NoDataBlock from '../../components/NoDataBlock.vue'

export default Vue.extend({
  name: 'dt-resources-block',
  components: { SectionEditIcon, NoDataBlock },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
})
