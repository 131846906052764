









import Vue from 'vue'
import GlobalConfirm from '@/ui/GlobalConfirm.vue'
import GlobalNotifier from '@/ui/GlobalNotifier.vue'
import Navbar from '@/features/nav/index.vue'

export default Vue.extend({
  name: 'compcon',
  components: {
    GlobalConfirm,
    GlobalNotifier,
    Navbar,
  },
  mounted() {
    Vue.prototype.$confirm = this.$refs.confirm.open
    Vue.prototype.$notify = this.$refs.notifier.notify.bind(this.$refs.notifier)
    // TODO: disable in dev
    Vue.prototype.$notifyError = this.$refs.notifier.notifyError.bind(this.$refs.notifier)
  },
})
