
























































import Vue from 'vue'
import ReserveSelector from './_ReserveSelector.vue'
import { Reserve } from '@/class'

export default Vue.extend({
  name: 'power-at-cost',
  components: { ReserveSelector },
  props: {
    pilot: { type: Object, required: true },
  },
  data: () => ({
    complication1: 'None',
    complication2: 'None',
    complications: [
      'None',
      'It’s going to take a lot more time than you anticipated',
      'It’s going to be really damn risky',
      'You have to have to give something up or leave something behind (wealth, resources, allies)',
      'You’re going to piss someone or something important and powerful off',
      'It’s going to involve going wildly off the plan',
      'You’ll need more information to proceed safely',
      'What you put together is going to fall apart damn soon',
      'You’ll need to gather more resources first (you know where to find them, however)',
      'You can’t exactly get what you want, just approximately what you want, a lesser version, or less of what you want',
    ],
  }),
  methods: {
    addReserve() {
      const rs = this.$refs.rs
      const r = rs.reserveByID(rs.reserve)
      const nr = new Reserve({
        id: rs.reserve || 'reserve_custom',
        type: rs.type,
        name: (r && r.name) || rs.custom_name || 'Custom Reserve',
        label: rs.custom_name,
        description: (r && r.description) || '',
        resource_name: rs.custom_name || '',
        resource_note: rs.details || '',
        resource_cost: this.complication1,
        consumable: true,
        used: false,
      })
      if (this.complication2 !== 'None') nr.ResourceCost += `\n${this.complication2}`
      this.pilot.ReservesController.AddReserve(nr)
      this.close()
    },
    close() {
      this.complication1 = 'None'
      this.complication2 = 'None'
      this.$refs.rs.reset()
      this.$emit('close')
    },
  },
})
