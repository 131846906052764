






















































































































































import _ from 'lodash'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'
import ItemSelectorRow from '../../components/_ItemSelectorRow.vue'
import InvadeDialog from './_InvadeDialog.vue'

import Vue from 'vue'
import { ActivationType } from '@/classes/enums'

export default Vue.extend({
  name: 'full-tech-dialog',
  components: { ActionDetailExpander, ItemSelectorRow, InvadeDialog },
  props: {
    used: { type: Boolean },
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    quick: [],
    full: [],
    MAX_QUICK: 2,
  }),
  computed: {
    state() {
      return this.mech.Pilot.State
    },
    invadeAction() {
      return this.state.TechActions.find(x => x.ID === 'act_invade')
    },
    quickActions() {
      const qtArr = this.state.TechActions.filter(
        x => x.Activation === ActivationType.QuickTech && x.ID !== 'act_invade'
      )
      return _.groupBy(qtArr, 'Origin')
    },
    fullActions() {
      return _.groupBy(
        this.state.TechActions.filter(x => x.Activation === ActivationType.FullTech),
        'Origin'
      )
    },
  },
  watch: {
    used: {
      immediate: true,
      deep: true,
      handler: function (newval) {
        if (!newval) this.quick = []
      },
    },
  },
  methods: {
    doFullTech(action) {
      const ref = `dialog_${action.ID}`
      this.$refs[ref][0].show()
    },
    init() {
      this.quick = this.quick.splice(0, this.quick.length)
    },
    addQuick(action) {
      if (action.IsTechAttack) this.doFullTech(action)
      else if (this.quick.length < this.MAX_QUICK) this.quick.push(action)
    },
    removeQuick(idx) {
      this.quick.splice(idx, 1)
    },
    addFull(action){
      if(this.full.length === 0)
        this.full.push(action)
    },
    removeFull(){
      this.full = []
    },
    openInvade() {
      this.$refs.inv_dialog.init()
      this.$refs.inv_dialog.show()
    },
    systemFromFailure(failureString) {
      return failureString.split('-')[2].toUpperCase()
    },
  },
})
