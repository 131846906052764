












































































































































import Vue from 'vue'
import EquipmentOptions from '../mech_loadout/components/_EquipmentOptions.vue'
import EquipmentHeader from '../mech_loadout/components/_EquipmentHeader.vue'

export default Vue.extend({
  name: 'active-system-card',
  components: {
    EquipmentOptions,
    EquipmentHeader,
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
    mech: {
      type: Object,
      required: true,
      default: null,
    },
    rest: {
      type: Boolean,
    },
  },
  data: () => ({
    hide: false,
  }),
  computed: {
    color() {
      return this.mech.Frame.Manufacturer.GetColor(this.$vuetify.theme.dark)
    },
  },
  methods: {
    actionDisabled(action) {
      if (!this.item) return true
      if (this.item.MaxUses && this.item.Uses === 0) return true
      if (this.item.Used) return true
      // if (!this.item.MaxUses && action.Deployable) {
      //   return this.mech.Pilot.State.Deployed.map(x => x.Name).includes(action.Deployable.name)
      // }
      return this.item.Destroyed || this.mech.IsStunned
    },
  },
})
