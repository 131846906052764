





















































































































import { ActivationType, DiceRoller } from '@/class'
import Vue from 'vue'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'
import ActionTitlebar from '../../components/_ActionTitlebar.vue'
import TechAttack from '../../components/_TechAttack.vue'
import ActionActivationButtons from '../../components/_ActionActivationButtons.vue'

export default Vue.extend({
  name: 'invade-dialog',
  components: { ActionDetailExpander, ActionTitlebar, TechAttack, ActionActivationButtons },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
    fulltech: { type: Boolean },
  },
  data: () => ({
    dialog: false,
    selected: null,
    succeeded: false,
    failed: false,
    complete: false,
    actionCost: false,
    actionFree: false,
    timer: 0,
    finished: false,
  }),
  computed: {
    state() {
      return this.mech.Pilot.State
    },
    actions() {
      return this.state.TechActions.filter(x => x.Activation === ActivationType.Invade)
    },
    skLog() {
      let l = ['UPLINK ESTABLISHED. ATTEMPTING REMOTE ACCESS.']
      if (this.succeeded) {
        l.push('SYSTEM INVASION SUCCESSFUL.')
        l = l.concat(this.selected.Log)
      } else l.push('ACCESS DENIED. INVASION FAILURE RECORDED.')
      return l
    },
    round() {
      return this.state.Round
    },
  },
  watch: {
    round: {
      immediate: true,
      deep: true,
      handler: function () {
        this.reset()
      },
    },
  },
  methods: {
    use(free) {
      this.actionFree = free
      this.actionCost = !free
    },
    runTimeout() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      const timer = setInterval(function () {
        self.timer++

        if (self.timer > self.skLog.length * 10) {
          clearInterval(timer)
          self.finished = true
        }
      }, 80)
    },
    select(a) {
      if (this.fulltech) {
        this.$emit('add-invade', a)
        this.init()
        this.hide()
      } else {
        this.state.CommitAction(this.action, this.actionFree)
      }
      this.$emit('use')
      this.selected = a
      this.runTimeout()
    },
    techAttackComplete(success) {
      if (success) {
        this.succeeded = true
      } else {
        if (this.fulltech) {
          this.$emit('add-fail')
          this.init()
          this.hide()
        } else {
          this.state.CommitAction(this.action, this.actionFree)
        }
        this.failed = true
        this.$emit('use')
        this.runTimeout()
      }
    },
    undo() {
      this.state.Undo(this.action, this.actionFree)
      this.reset()
    },
    reset() {
      this.$emit('undo')
      this.init()
    },
    init() {
      this.succeeded = false
      this.failed = false
      this.complete = false
      this.actionCost = false
      this.actionFree = false
      this.timer = 0
      this.finished = false
      this.selected = null
    },
    show(): void {
      this.dialog = true
    },
    hide(): void {
      this.init()
      this.dialog = false
    },
  },
})
