















import Vue from 'vue'
import ActionActivationButtons from '../../components/_ActionActivationButtons.vue'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'

export default Vue.extend({
  name: 'action-dialog-base',
  components: { ActionDetailExpander, ActionActivationButtons },
  props: {
    used: { type: Boolean },
    fulltech: { type: Boolean, default: false },
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
})
