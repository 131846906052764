


















































import Vue from 'vue'
import SectionEditChip from '../../components/SectionEditChip.vue'
import NoDataBlock from '../../components/NoDataBlock.vue'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'

export default Vue.extend({
  name: 'skill-block',
  components: { SectionEditChip, NoDataBlock },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  computed: {
    profile() {
      return getModule(UserStore, this.$store).UserProfile
    },
    ctype: {
      get: function (): string {
        return this.profile.GetView('talents')
      },
      set: function (newval: string) {
        this.profile.SetView('talents', newval)
      },
    },
  },
})
