





















import { Pilot } from '@/classes/pilot/Pilot'
import Vue from 'vue'
export default Vue.extend({
  name: 'mass-delete',
  props: {
    pilots: { type: Array, required: true },
  },
  data: () => ({
    selected: [],
    headers: [
      {
        text: 'Callsign',
        align: 'start',
        value: 'Callsign',
      },
      { text: 'LL', value: 'Level' },
      { text: 'Name', value: 'Name' },
      { text: 'Active Mech', value: 'State.ActiveMech.Name' },
      { text: 'Group', value: 'GroupController.Group' },
    ],
  }),
  methods: {
    commit() {
      this.selected.forEach((p: Pilot) => {
        p.SaveController.delete()
      })
      this.$set(this, 'selected', [])
    },
  },
})
