

























import Vue from 'vue'
export default Vue.extend({
  name: 'print-action',
  props: {
    actions: {
      type: Array,
      required: true,
    },
  },
})
