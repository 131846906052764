var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"packsList"},[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-pagination":"","no-data-text":"No content packs installed.","headers":_vm.headers,"items":_vm.contentPacks,"show-expand":"","item-key":"Key"},scopedSlots:_vm._u([{key:"item.toggleActive",fn:function(ref){
var item = ref.item;
return [(!item.Missing)?_c('v-switch',{attrs:{"input-value":item.Active,"color":"primary"},on:{"change":function($event){return _vm.toggleActive(item.ID, $event)}}}):_c('cc-tooltip',{attrs:{"location":"top","inline":"","content":"This pack is missing one or more dependencies and cannot be activated."}},[_c('v-icon',{attrs:{"color":"error"},domProps:{"textContent":_vm._s('mdi-alert')}})],1)]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"heading h3",class:item.Active ? 'accent--text' : 'subtle--text font-italic',style:(item.Missing ? 'opacity: 0.4' : '')},[_vm._v(" "+_vm._s(item.Name)+" ")])]}},{key:"item.Version",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"packVersion",style:(item.Missing ? 'opacity: 0.4' : '')},[_vm._v(" "+_vm._s(item.Version)+" ")])]}},{key:"item.deleteAction",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","offset-x":"","top":"","nudge-left":"30px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"fadeSelect",attrs:{"icon":"","color":"primary"}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-text',{staticClass:"text-center body-text"},[_c('p',[_vm._v(" This will remove this pack and all of its contents from COMP/CON. User data that relies on this content will be unavailable and may cause errors. Are you sure you want to continue? ")]),_c('v-divider',{staticClass:"my-2"}),_c('v-row',{attrs:{"dense":""}},[_c('v-btn',{attrs:{"small":"","text":""}},[_vm._v("CANCEL")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deletePack(item.ID)}}},[_vm._v(" CONFIRM ")])],1)],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{staticClass:"py-4 px-6 w-100 light-panel",attrs:{"colspan":headers.length}},[_c('pack-info-card',{attrs:{"pack":item}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }