var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('p',{staticClass:"text-center body-text",domProps:{"innerHTML":_vm._s(
        'You try and make connections, call upon favors, ask for help, or drum up support for a particular course of action. You need access to communications or just good old fashioned face to face conversation to take this action. <br /> You can use your connection’s resources or aid as <strong>reserves</strong> for the next mission.'
      )}}),_c('v-divider',{staticClass:"mb-2"}),_c('div',{staticClass:"pt-2 heading h3 text-center"},[_vm._v(" Roll "),_c('v-icon',{attrs:{"large":"","color":"accent"}},[_vm._v("mdi-dice-d20")]),_vm._v("  and add any relevant Skill Trigger bonuses, modifiers, or accuracy ")],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"type":"number","label":"Roll Result","outlined":"","dense":"","hide-details":"","append-outer-icon":"mdi-plus-circle-outline","prepend-icon":"mdi-minus-circle-outline"},on:{"click:append-outer":function($event){_vm.skillRoll++},"click:prepend":function($event){_vm.skillRoll > 1 ? _vm.skillRoll-- : ''}},model:{value:(_vm.skillRoll),callback:function ($$v) {_vm.skillRoll=$$v},expression:"skillRoll"}})],1)],1),_c('v-slide-y-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.skillRoll),expression:"skillRoll"}],staticClass:"text-center flavor-text",attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[(_vm.skillRoll < 10)?_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'You’ve got to do a favor or make good on a promise for your connection <em>right now</em> . Otherwise, they won’t help you at all. <br /> If you take action right away, however, they’ll go along with what you want.'
            )}}):(_vm.skillRoll < 20)?_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'Your connection will help you, but you’ve got to do a favor or make good on a promise <em>after</em> they help you. <br /> If you don’t, treat any result as a 9 or lower next time with the same organization.'
            )}}):_c('p',{staticClass:"font-weight-bold px-3",domProps:{"innerHTML":_vm._s(
              'Your connection will help you out, no strings attached. <br /> Treat this result as a 10-19 if you make it again with the same organization.'
            )}})]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',{staticClass:"ml-5 mr-5 mt-2",attrs:{"color":"panel"}},[_c('v-toolbar',{attrs:{"dark":"","dense":"","color":"action--downtime"}},[_c('v-toolbar-title',[_vm._v("Connection")])],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Contact or Organization Name","outlined":"","dense":"","hide-details":""},model:{value:(_vm.custom_name),callback:function ($$v) {_vm.custom_name=$$v},expression:"custom_name"}}),_c('v-textarea',{attrs:{"auto-grow":"","rows":"1","label":"Details"},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}})],1)],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"large":"","tile":"","color":"primary","disabled":!_vm.skillRoll || !_vm.custom_name},on:{"click":_vm.addReserve}},[_vm._v(" add reserve ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }