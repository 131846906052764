

































import Vue from 'vue'

export default Vue.extend({
  name: 'equipment-header',
  props: {
    item: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    useBonus: {
      type: Number,
      required: false,
      default: 0,
    },
    interior: {
      type: Boolean,
    },
    dark: {
      type: Boolean,
    },
    mod: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
})
