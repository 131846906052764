









































































import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

import _ from 'lodash'

export default vueMixins(activePilot).extend({
  name: 'clone-block',
  props: {
    hideQuirks: { type: Boolean },
    readonly: { type: Boolean },
  },
  methods: {
    setQuirk() {
      if (!this.pilot.Callsign.includes('※')) this.pilot.Callsign += '※'
      if (!this.pilot.Callsign.includes('※')) this.pilot.Name += '※'
      this.pilot.Heal()
      const compendium = getModule(CompendiumStore, this.$store)
      this.pilot.AddQuirk(_.sample(compendium.Tables.quirks))
    },
    updateQuirk(index, str) {
      this.$set(this.pilot.Quirks, index, str)
    },
  },
})
