


















import Vue from 'vue'
import FileImport from './add_panels/FileImport.vue'
import ShareImport from './add_panels/ShareImport.vue'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'

export default Vue.extend({
  name: 'import-dialog',
  components: { FileImport, ShareImport },
  computed: {
    isAuthed() {
      return getModule(UserStore, this.$store).IsLoggedIn
    },
  },
})
