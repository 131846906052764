





































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component({ name: 'cc-dialog' })
export default class CCDialog extends Vue {
  @Prop({ type: Boolean })
  readonly small?: boolean
  @Prop({ type: Boolean })
  readonly fullscreen?: boolean
  @Prop({ type: Boolean })
  readonly smallBtn?: boolean
  @Prop({ type: Boolean })
  readonly large?: boolean

  @Prop({ type: String, required: false, default: 'primary' })
  readonly color: string

  @Prop({ type: Boolean })
  readonly flat?: boolean
  @Prop({ type: Boolean })
  readonly dark?: boolean
  @Prop({ type: Boolean })
  readonly noConfirm?: boolean
  @Prop({ type: Boolean })
  readonly noDismiss?: boolean

  dialog = false

  @Emit()
  confirm(): void {
    this.dialog = false
  }
}
