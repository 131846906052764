

























































import Vue from 'vue'
export default Vue.extend({
  name: 'player-equipment-item',
  props: {
    item: {
      type: Object,
      required: true,
    },
    limitedBonus: {
      type: Number,
      required: false,
      default: 0,
    },
  },
})
