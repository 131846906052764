


























































































































































import Vue from 'vue'
import EditMenu from './PilotEditMenu.vue'
import ShareDialog from './ShareDialog.vue'
import { getModule } from 'vuex-module-decorators'
import { PilotManagementStore, CompendiumStore, UserStore } from '@/store'
import { Auth } from 'aws-amplify'
import { RemoteSyncItem } from '@/cloud/item_sync'

export default Vue.extend({
  name: 'pilot-nav',
  components: {
    EditMenu,
    ShareDialog,
  },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  async mounted() {
    // await Auth.currentAuthenticatedUser()
  },
  computed: {
    lastLoaded() {
      const store = getModule(PilotManagementStore, this.$store)
      return this.pilot.Mechs.some(x => x.ID === store.LoadedMechID)
        ? store.LoadedMechID
        : this.pilot.ActiveMech
        ? this.pilot.ActiveMech.ID
        : null
    },
    isAuthed() {
      return getModule(UserStore, this.$store).IsLoggedIn
    },
    hasBondData() {
      return getModule(CompendiumStore, this.$store).Bonds.length
    },
  },
  methods: {
    toMech() {
      this.$router.push(`../mech/${this.lastLoaded}`)
    },
    toActive() {
      this.$router.push(`/active/${this.pilot.ID}`)
    },
    delete_pilot() {
      this.pilot.SaveController.delete()
      this.$router.push('/pilot_management')
    },
    async remoteUpdate() {
      this.loading = true
      try {
        await RemoteSyncItem(this.pilot)
        this.$notify('Pilot synced to remote', 'success')
      } catch (error) {
        console.error(error)
        this.$notify('An error occurred while attempting to download remote data', 'error')
      }
      this.loading = false
    },
  },
})
