








































































































































import { ItemType } from '@/class'
import Vue from 'vue'

export default Vue.extend({
  name: 'slot-card-base',
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
    mech: {
      type: Object,
      required: true,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
  computed: {
    small() {
      return this.$vuetify.breakpoint.smAndDown
    },
    synergyLocation() {
      if (!this.item) return 'none'
      return this.item.ItemType === ItemType.MechWeapon ? 'weapon' : 'system'
    },
  },
})
