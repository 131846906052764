
















































import Vue from 'vue'
import { Auth } from '@aws-amplify/auth'

export default Vue.extend({
  name: 'auth-account-verify',
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    error: null,
    showError: false,
    loading: false,
    show: false,
    verify: null,
  }),
  methods: {
    confirm() {
      this.loading = true
      // After retrieveing the confirmation code from the user
      Auth.confirmSignUp(this.email.toLowerCase(), this.verify, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true,
      })
        .then(data => {
          this.loading = false
          this.$notify('User Account created successfully. Redirecting to Sign-In.')
          console.log(data)
          this.$emit('set-state', 'sign-in')
        })
        .catch(error => {
          this.loading = false
          this.showError = true
          this.error = `${error.message}<br><div class='text-right'>${error.name}</div>`
        })
    },
    resend() {
      Auth.resendSignUp(this.email).then(res => {
        console.log(res)
        this.$notify(`New verification e-mail sent to ${this.email.toLowerCase()}`).catch(err => {
          console.error(err)
          this.$notify(`Error sending verification e-mail: ${err}`)
        })
      })
    },
  },
})
