































import Vue from 'vue'

export default Vue.extend({
  name: 'cc-bond-info',
  props: {
    power: { type: Object, required: true },
    flexHeight: { type: Boolean },
    disabled: { type: Boolean },
  },
  computed: {
    headerColor() {
      if (this.power.veteran) return 'indigo lighten-1'
      if (this.power.master) return 'deep-purple darken-3'
      return 'panel darken-2'
    },
  },
})
