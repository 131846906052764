














































import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'
import ItemSelectorRow from '../../components/_ItemSelectorRow.vue'
import WBarrageDialog from './_SelBarrageDialog.vue'
import ShBarrageDialog from './_SelSHBarrageDialog.vue'

import Vue from 'vue'

export default Vue.extend({
  name: 'barrage-dialog',
  components: {
    ActionDetailExpander,
    WBarrageDialog,
    ShBarrageDialog,
    ItemSelectorRow,
  },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  computed: {
    state() {
      return this.mech.Pilot.State
    },
  },
  methods: {
    disableShBarrage(w) {
      if (w.IsLoading && !w.Loaded) return true
      if (w.IsOrdnance && !this.state.IsProtocolAvailable) return true
      return w.Used || this.state.BarrageSelections.length > 0 || this.state.Actions < 2
    },
    disableBarrage(w) {
      if (w.IsLoading && !w.Loaded) return true
      if (w.IsOrdnance && !this.state.IsProtocolAvailable) return true
      return w.Used || this.state.Actions < 2
    },
    barrageToggle(w) {
      return this.state.BarrageSelections.some(x => x === w)
    },
    setBarrage(item, mount) {
      if (this.state.BarrageSelections.some(x => x === item)) this.state.ClearBarrageSelections()
      else if (this.state.BarrageSelections.length < 2) {
        this.state.SelectBarrage(item, mount)
        if (this.state.BarrageSelections.length === 2) {
          this.$refs.b_dialog.show()
        }
      }
    },
    setSHBarrage(item, mount) {
      this.state.SelectShBarrage(item, mount)
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      Vue.nextTick().then(() => Vue.nextTick().then(() => self.$refs.sh_b_dialog.show()))
    },
    completeBarrage() {
      this.$emit('use')
    },
    undoBarrage() {
      this.$emit('undo')
    },
  },
})
