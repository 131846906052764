









































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import TableWindowItem from './_TableWindowItem.vue'
import ResultData from './_stress_results.json'
import CascadeCheck from './_CascadeCheck.vue'
import { Mech } from '@/class'

@Component({
  name: 'stress-table',
  components: { TableWindowItem, CascadeCheck },
})
export default class CCStressTable extends Vue {
  dialog = false
  show(): void {
    this.dialog = true
  }
  close(): void {
    this.window = 0
    this.rolls = []
    this.dialog = false
  }
  window = 0

  @Prop({ type: Object, required: true })
  mech!: Mech

  rolls = []
  resultData = ResultData
  results = [
    'Meltdown',
    'Power Plant Destabilize',
    'Power Plant Destabilize',
    'Power Plant Destabilize',
    'Emergency Shunt',
    'Emergency Shunt',
  ]

  get totalRolls(): number {
    return (this.mech.CurrentStress - this.mech.MaxStress) * -1
  }
  get resultWindow(): number {
    if (this.rolls.filter(x => x === 1).length > 1) return 4
    switch (Math.min(...this.rolls)) {
      case 6:
      case 5:
        return 1
      case 4:
      case 3:
      case 2:
        return 2
      case 1:
        return this.mech.CurrentStress <= 1 ? 4 : 3
    }
    return 4
  }

  rollRandom(): number {
    return Math.floor(Math.random() * 6) + 1
  }

  applyES(): void {
    this.mech.AddCondition('IMPAIRED')
    this.close()
  }
  applyPPD(): void {
    this.mech.AddCondition('EXPOSED')
    this.close()
  }
  applyMeltdown(): void {
    this.mech.Pilot.State.ReactorCriticalDestruct()
    this.close()
  }
}
