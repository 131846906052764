
















import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'
import DowntimeMenuItem from './DowntimeMenuItem.vue'

export default vueMixins(activePilot).extend({
  name: 'downtime-action-menu',
  components: { DowntimeMenuItem },
  props: {
    tab: { type: Number, required: true, default: 1 },
  },
})
