var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode),expression:"mode"}],staticClass:"clipped-large no-print",staticStyle:{"z-index":"50"},attrs:{"app":"","fixed":"","top":"","color":"primary","dark":"","dense":""}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500ms"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.historyNav(-1)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Back")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500ms"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.historyNav(1)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-arrow-right")])],1)]}}])},[_c('span',[_vm._v("Forward")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500ms"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.home()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-home")])],1)]}}])},[_c('span',[_vm._v("Main Menu")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500ms"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{attrs:{"to":"/compendium"}},[_c('v-btn',_vm._g({attrs:{"text":"","icon":""}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-book")])],1)],1)]}}])},[_c('span',[_vm._v("Compendium")])]),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":"","dark":""}}),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-toolbar-title',[_c('span',{staticClass:"heading"},[_vm._v("COMP/CON")]),_c('span',{staticClass:"flavor-text white--text"},[_vm._v(_vm._s(_vm.$appVersion))])]):_vm._e(),_c('v-spacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[(_vm.mode === 'pilot')?_c('pilot-mode'):_vm._e(),(_vm.mode === 'compendium')?_c('compendium-mode'):_vm._e(),(_vm.mode === 'encounter')?_c('encounter-mode'):_vm._e()],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp && _vm.isAuthed)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":"","dark":""}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp && _vm.isAuthed)?_c('cc-tooltip',{attrs:{"bottom":"","content":"Open cloud account menu"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$refs.cloudModal.show()}}},[_c('v-icon',[_vm._v("mdi-cloud-sync-outline")])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":"","dark":""}}):_vm._e(),_c('cc-tooltip',{attrs:{"bottom":"","content":"Help & FAQ"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$refs.helpModal.show()}}},[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":"","dark":""}}),_c('v-menu',{attrs:{"nudge-bottom":"40px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.$refs.contentModal.show()}}},[_vm._v("Manage Content")]),_c('v-list-item',{on:{"click":function($event){return _vm.$refs.optionsModal.show()}}},[_vm._v("Options")]),_c('v-list-item',{on:{"click":function($event){return _vm.$refs.aboutModal.show()}}},[_vm._v("About")]),_c('v-list-item',{on:{"click":function($event){return _vm.$refs.creditsModal.show()}}},[_vm._v("Credits")]),_c('v-list-item',{on:{"click":function($event){return _vm.$refs.helpModal.show()}}},[_vm._v("Help")]),_c('v-divider'),_c('v-list-item',{attrs:{"target":"_blank","href":"https://www.patreon.com/compcon"}},[_vm._v(" Support COMP/CON ")])],1)],1),_c('v-spacer',{staticStyle:{"max-width":"20px"}}),_c('cc-solo-dialog',{ref:"contentModal",attrs:{"no-title-clip":"","no-pad":"","large":"","no-confirm":"","title":"Manage Content Packs"}},[_c('content-page')],1),_c('cc-solo-dialog',{ref:"optionsModal",attrs:{"large":"","no-confirm":"","no-pad":"","no-title-clip":"","title":"Options & User Profile"}},[_c('options-page')],1),_c('cc-solo-dialog',{ref:"aboutModal",attrs:{"large":"","no-confirm":"","title":"About"}},[_c('about-page')],1),_c('cc-solo-dialog',{ref:"creditsModal",attrs:{"large":"","no-confirm":"","title":"Credits"}},[_c('credits-page')],1),_c('cc-solo-dialog',{ref:"helpModal",attrs:{"large":"","no-confirm":"","title":"Help"}},[_c('help-page')],1),_c('cc-solo-dialog',{ref:"creditsModal",attrs:{"fullscreen":"","no-confirm":"","title":"Credits"}},[_c('credits-page')],1),_c('cc-solo-dialog',{ref:"cloudModal",attrs:{"large":"","no-confirm":"","title":"Cloud Account"}},[_c('cloud-page')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }