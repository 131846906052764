

































import SectionEditIcon from '../../components/SectionEditIcon.vue'
import NoDataBlock from '../../components/NoDataBlock.vue'
import activePilot from '@/features/pilot_management/mixins/activePilot'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify'

import vueMixins from '@/util/vueMixins'

export default vueMixins(activePilot).extend({
  name: 'appearance-block',
  components: { SectionEditIcon, NoDataBlock, TiptapVuetify },
  data: () => ({
    appearance: '',
    extensions: [
      History,
      Blockquote,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      HardBreak,
    ],
  }),

  created() {
    // this.$vuetify.lang.current = 'en'
    this.appearance = this.pilot.TextAppearance || ''
  },
  methods: {
    show() {
      this.appearance = this.pilot.TextAppearance || ''
      this.$refs.dialog.show()
    },
  },
})
