






























































































import Vue from 'vue'
import EquipmentOptions from '../../mech_loadout/components/_EquipmentOptions.vue'
import EquipmentHeader from '../../mech_loadout/components/_EquipmentHeader.vue'

export default Vue.extend({
  name: 'active-mod-inset',
  components: { EquipmentOptions, EquipmentHeader },
  props: {
    mod: {
      type: Object,
      required: true,
    },
    mech: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    action: { type: Boolean },
  },
})
