

















































































































































import HelpPage from './pages/Help.vue'
import AboutPage from './pages/About.vue'
import CreditsPage from './pages/Credits.vue'
import OptionsPage from './pages/Options/index.vue'
import ContentPage from './pages/ExtraContent/index.vue'
import CloudPage from './pages/Cloud.vue'
import activePilot from '../pilot_management/mixins/activePilot'

import PilotMode from './modes/pilot.vue'
import EncounterMode from './modes/encounter.vue'
import CompendiumMode from './modes/compendium.vue'

import vueMixins from '@/util/vueMixins'
import { getModule } from 'vuex-module-decorators'
import { PilotManagementStore, UserStore, NavStore } from '@/store'
import { Auth } from 'aws-amplify'

export default vueMixins(activePilot).extend({
  name: 'cc-nav',
  components: {
    HelpPage,
    AboutPage,
    CreditsPage,
    OptionsPage,
    ContentPage,
    PilotMode,
    EncounterMode,
    CompendiumMode,
    CloudPage,
  },
  props: {
    pilotManagement: { type: Boolean },
    encounter: { type: Boolean },
  },
  data: () => ({
    aboutDialog: false,
    helpDialog: false,
    optionsDialog: false,
  }),
  async mounted() {
    // await Auth.currentAuthenticatedUser()
  },
  computed: {
    mode(): string {
      return getModule(NavStore, this.$store).NavMode
    },
    unsaved() {
      return getModule(PilotManagementStore, this.$store).unsavedCloudPilots
    },
    isAuthed() {
      return getModule(UserStore, this.$store).IsLoggedIn
    },
    syncTooltip(): string {
      if (!this.unsaved.length) return 'Pilot data synced'
      return (
        '<div class="text-center"><b>LOCAL CHANGES<br></b>' +
        this.unsaved.map(p => `Pilot::${p.Callsign}`).join('<br>') +
        '<br><span class="caption">Click to save changes to your cloud account</span></div>'
      )
    },
  },
  methods: {
    sync() {
      // getModule(UserStore, this.$store).cloudSync({
      //   callback: (status, message) => this.$notify(status, message),
      // })
    },
    home() {
      this.$router.push('/')
    },
    historyNav(dir: number) {
      this.$router.go(dir)
    },
  },
})
