






import Vue from 'vue'
export default Vue.extend({
  name: 'synergy-base',
  props: {
    synergy: {
      type: Object,
      required: true,
    },
  },
})
