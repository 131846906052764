




















































































import Vue from 'vue'
import ItemFilter from '@/classes/utility/ItemFilter'
import SelectorTableView from './views/_SelectorTableView.vue'
import SelectorSplitView from './views/_SelectorSplitView.vue'
import SelectorMobileView from './views/_SelectorMobileView.vue'
import SelectorCardsView from './views/_SelectorCardsView.vue'
import { accentInclude } from '@/classes/utility/accent_fold'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'
import { UserProfile } from '@/user'

export default Vue.extend({
  name: 'cc-selector-table',
  components: { SelectorTableView, SelectorSplitView, SelectorMobileView, SelectorCardsView },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    noFilter: {
      type: Boolean,
      required: false,
    },
    noExtra: {
      type: Boolean,
      required: false,
    },
    itemTypeFallback: {
      type: String,
      required: false,
      default: '',
    },
    spDisable: { type: Boolean },
    spIgnore: { type: Boolean },
    equipmentAdd: { type: Boolean },
    sp: { type: Number, required: false, default: 0 },
  },
  data: () => ({
    search: '',
    filters: {},
    itemType: '',
  }),
  computed: {
    profile(): UserProfile {
      const store = getModule(UserStore, this.$store)
      return store.UserProfile
    },
    fItems() {
      const vm = this as any
      let i = vm.items

      if (vm.search) i = i.filter(x => accentInclude(x.Name, vm.search))

      if (Object.keys(vm.filters).length) {
        i = ItemFilter.Filter(i, vm.filters)
      }

      return i
    },
  },
  created() {
    if (!this.itemType) this.itemType = this.itemTypeFallback
  },
  methods: {
    setFilters(newFilter) {
      this.filters = newFilter
    },
  },
})
