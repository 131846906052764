























/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Vue from 'vue'
import Component from 'vue-class-component'
import updateChecker from '@/util/UpdateChecker'

@Component
export default class UpdatesTracker extends Vue {
  updateFound = false
  checking = false
  async checkUpdates() {
    this.checking = true

    await updateChecker.checkUpdates()

    this.checking = false
  }

  created() {
    updateChecker.on('updatefound', () => (this.updateFound = true))
  }

  mounted() {
    if (updateChecker.updateAvailable) this.updateFound = true
  }

  get updateText(): string {
    return 'Update and Reload'
  }
  updateClick() {
    updateChecker.getUpdate()
  }
}
