



























































import Vue from 'vue'
import { reserves } from '@massif/lancer-data'

export default Vue.extend({
  name: 'reserve-selector',
  data: () => ({
    type: '',
    reserve: '',
    custom_name: '',
    details: '',
    reserveTypes: ['Resource', 'Tactical', 'Mech', 'Custom'],
  }),
  computed: {
    reserveComplete() {
      return (
        (this.type && this.reserve) || (this.type === 'Custom' && this.custom_name && this.details)
      )
    },
    reserves() {
      return reserves.filter(x => x.type === this.type)
    },
  },
  methods: {
    reserveByID(id) {
      return reserves.find(x => x.id === id)
    },
    reset() {
      this.type = ''
      this.reserve = ''
      this.custom_name = ''
      this.details = ''
    },
  },
})
