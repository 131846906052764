



























































































































































































































































import ActionMenuButton from '../../components/ActionMenuButton.vue'
import MoveMenuButton from '../../components/MoveMenuButton.vue'
import ActionMenu from '../../components/ActionMenu.vue'
import BurnDialog from '../../components/BurnDialog.vue'
import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'

export default vueMixins(activePilot).extend({
  name: 'combat-footer',
  components: { MoveMenuButton, ActionMenuButton, ActionMenu, BurnDialog },
  data: () => ({
    menuTab: 1,
    ecDialog: false,
    roundConfirm: false,
    turnConfirm: false,
  }),
  computed: {
    state() {
      return this.pilot.State
    },
    mech() {
      return this.pilot.ActiveMech
    },
    nextRoundConfirm() {
      let str = ''
      if (this.state.Actions > 0) str += `<div class='px-2'>Actions available</div>`
      if (this.state.Move > 0) str += `<div class='px-2'>Movement available</div>`
      if (str.length) {
        str = `<div class='error--text'>ALERT::<div>${str}`
      } else
        str =
          '<div>Confirm Round Complete</div> <div class="text--secondary">This cannot be undone.</div>'
      return str
    },
    endTurnConfirm() {
      let str = ''
      if (this.state.Actions > 0) str += `<div class='px-2'>Actions available</div>`
      if (this.state.Move > 0) str += `<div class='px-2'>Movement available</div>`
      if (str.length) {
        str = `<div class='error--text'>ALERT::<div>${str}`
      } else str = ''
      return str
    },
  },
  methods: {
    stageEndTurn() {
      if (this.mech.Burn) this.$refs.burnDialog.show()
      else this.endTurn()
    },
    stageNextRound() {
      this.nextRound()
    },
    nextRound() {
      this.state.NextRound()
      this.roundConfirm = false
    },
    endTurn(burn) {
      this.state.EndTurn(burn?.hp || 0, burn?.str || 0)
      this.turnConfirm = false
    },
    undoEndTurn() {
      this.state.UndoEndTurn()
    },
    openMenu(tab) {
      this.menuTab = tab
      this.$refs.actionMenu.show()
    },
    openDialog(action) {
      const r = this.$refs[`dialog_${action.ID}`]
      if (r && r[0]) r[0].show()
    },
  },
})
