





































































































































































































































































































import Vue from 'vue'
import path from 'path'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'
import { storageInfo, getPresignedLink, s3api, deleteStorage } from '@/user/api'
import { Auth } from '@aws-amplify/auth'
import artistmap from '@/assets/artistmap.json'

export default Vue.extend({
  name: 'web-image-selector',
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    currentUserPage: 1,
    currentArtistPage: 1,
    itemsPerPage: 12,
    selectedImage: null,
    loading: false,
    imageSelectTab: 0,
    remoteInput: '',
    remoteError: '',
    accountUsage: 0,
    accountMax: 250,
    iid: '',
    userStorageData: null,
    stagedImage: null,
    showAll: false,
  }),
  async mounted() {
    if (!this.iid) await this.getStorageInfo()
  },
  computed: {
    displayedUserImages() {
      const startIndex = (this.currentUserPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      return this.userImages.slice(startIndex, endIndex)
    },
    totalUserPages() {
      return Math.ceil(this.userImages.length / this.itemsPerPage)
    },
    displayedArtistImages() {
      const startIndex = (this.currentArtistPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      return this.artistImages.slice(startIndex, endIndex)
    },
    totalArtistPages() {
      return Math.ceil(this.artistImages.length / this.itemsPerPage)
    },
    displayImage() {
      if (this.selectedImage)
        return this.selectedImage.url ? this.selectedImage.url : this.selectedImage
      if (this.item.Portrait) return this.item.Portrait
      else return 'https://via.placeholder.com/550'
    },
    isAuthed() {
      return getModule(UserStore, this.$store).IsLoggedIn
    },
    isOverCapacity() {
      return (
        this.isAuthed &&
        this.userStorageData &&
        this.userStorageData.totalSize >= this.userStorageData.max
      )
    },
    userImages() {
      if (!this.userStorageData || this.userStorageData.contents.length === 0) return []
      const contents = this.userStorageData.contents
        .flatMap(x => x.objects)
        .map(x => ({
          url: `https://d1nurxym97qk9o.cloudfront.net/${x.Key}`,
          filename: x.Key.split('/').pop(),
          tag: x.Key.split('/').slice(-2, -1)[0],
          size: x.Size / 1000000,
          key: x.Key,
        }))
      return contents
    },
    artistImages() {
      let out = []

      artistmap.forEach(artist => {
        if (this.showAll) {
          Object.keys(artist.images).forEach(tag => {
            artist.images[tag].forEach(image => {
              out.push({
                url: image.img,
                filename: image.name,
                tag: tag,
                artist: artist.artist,
                website: artist.website || '',
                twitter: artist.twitter || '',
              })
            })
          })
        } else {
          if (artist.images[this.type])
            artist.images[this.type].forEach(image => {
              out.push({
                url: image.img,
                filename: image.name,
                tag: this.type,
                artist: artist.artist,
                website: artist.website || '',
                twitter: artist.twitter || '',
              })
            })
        }
      })
      return out
    },
    selectedImageUrl() {
      return this.selectedImage ? this.selectedImage.url : ''
    },
  },
  methods: {
    async getStorageInfo() {
      if (this.isAuthed) {
        const res = await Auth.currentUserCredentials()
        this.iid = res.identityId
        const info = await storageInfo(this.iid)
        this.userStorageData = info.data
        if (this.userStorageData) {
          this.accountUsage = info.data.totalSize / 1000000
          this.accountMax = info.data.max / 1000000
        }
      }
    },
    isSelected(url) {
      return this.selectedImageUrl === url
    },
    clearImage() {
      this.item.PortraitController.SetCloudImage('')
      this.selectedImage = null
    },
    saveImage() {
      this.item.PortraitController.CloudImage =
        typeof this.selectedImage === 'string' ? this.selectedImage : this.selectedImage.url
      this.loading = false
      this.close()
    },
    setRemoteImage() {
      console.log(this.remoteInput)
      console.log(this.validURL(this.remoteInput))

      if (!this.remoteInput) return
      if (!this.validURL(this.remoteInput)) {
        this.remoteError = 'Invalid URL'
        return
      }

      const http = new XMLHttpRequest()

      http.open('GET', this.remoteInput, false)
      http.send()

      if (http.status === 404) {
        this.remoteError = 'Image not found'
        return
      }

      this.remoteError = ''
      this.selectedImage = this.remoteInput
    },
    setStagedImage() {
      if (!this.stagedImage) return
      this.selectedImage = null
      this.imageUrl = URL.createObjectURL(this.stagedImage)
    },
    uploadImage() {
      if (!this.stagedImage) return
      this.loading = true

      getPresignedLink(this.iid, 'image', this.type, this.stagedImage.name)
        .then(res => {
          const sUrl = res.data

          const reader = new FileReader()
          reader.onloadend = () => {
            s3api
              .put(sUrl, reader.result)
              .then(() => {
                this.loading = false
              })
              .then(() => {
                this.stagedImage = null
                this.getStorageInfo()
              })
              .catch(err => {
                this.loading = false
                console.error(err)
              })
          }

          reader.readAsArrayBuffer(this.stagedImage)
        })
        .catch(err => {
          this.loading = false
          console.error(err)
        })
    },
    async deleteCloudImage(image) {
      deleteStorage(image.key).then(() => {
        this.selectedImage = null
        this.getStorageInfo()
      })
    },
    // Pulled from Stackoverflow: https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
    validURL(str: string): boolean {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ) // fragment locator
      return !!pattern.test(str)
    },
    open() {
      this.$refs.dialog.show()
      if (!this.iid) this.getStorageInfo()
    },
    close() {
      this.$refs.dialog.hide()
    },
  },
})
