import { render, staticRenderFns } from "./_JockeyDialog.vue?vue&type=template&id=51a25614&"
import script from "./_JockeyDialog.vue?vue&type=script&lang=ts&"
export * from "./_JockeyDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCol,VDialog,VDivider,VIcon,VRow,VSlideXReverseTransition,VSlideYReverseTransition,VSpacer,VTextField,VToolbar})
