









































import Vue from 'vue'
import Component from 'vue-class-component'

import PacksList from './PacksList.vue'
import MissingContent from './MissingContent.vue'
import PackInstall from './PackInstall.vue'
import PacksDirectory from './PacksDirectory.vue'

@Component({
  components: { PacksList, PackInstall, PacksDirectory, MissingContent },
})
export default class ExtraContent extends Vue {
  public tabs = null

  public onInstalled(): void {
    ;(this.$refs.pl as any).reload()
    this.tabs = 0
  }
}
