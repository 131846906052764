



























import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'cc-license-panel',
  props: {
    license: {
      type: Object,
      required: true,
    },
    ranked: {
      type: Boolean,
      required: false,
    },
    rank: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    getLicense() {
      const licenseData = getModule(CompendiumStore, this.$store).Licenses.find(
        x => x.FrameID === this.license.FrameID
      )
      return licenseData
    },
  },
  methods: {
    align(n: number): string {
      return n === 1 ? 'left' : n === 2 ? 'center' : 'right'
    },
  },
})
