





















































































































































































































































































































































































import Vue from 'vue'
import EditableAttribute from './components/EditableAttribute.vue'
import SizeAttribute from './components/SizeAttribute.vue'
import FeatureSelector from './components/FeatureSelector.vue'
import TemplateSelector from './components/TemplateSelector.vue'
import { NpcFeature, NpcTemplate } from '@/class'
import { getModule } from 'vuex-module-decorators'
import { UserStore, NpcStore } from '@/store'
import { UserProfile } from '@/user'

export default Vue.extend({
  name: 'npc-card',
  components: {
    EditableAttribute,
    FeatureSelector,
    TemplateSelector,
    SizeAttribute,
  },
  props: {
    npc: {
      type: Object,
      required: false,
      default: null,
    },
  },
  // TODO: put these in data
  data: () => ({
    flavor_dialog: false,
    tactics_dialog: false,
    tags: ['Mech', 'Vehicle', 'Ship', 'Biological', 'Squad'],
  }),
  computed: {
    labels() {
      const store = getModule(NpcStore, this.$store)
      return store.Npcs.flatMap(x => x.Labels).filter(x => x)
    },
    campaigns() {
      const store = getModule(NpcStore, this.$store)
      return store.Npcs.map(x => x.Campaign).filter(x => x)
    },
    profile(): UserProfile {
      const store = getModule(UserStore, this.$store)
      return store.UserProfile
    },
  },
  methods: {
    equip(feat: NpcFeature) {
      this.npc.AddFeature(feat)
      this.$refs.featureDialog.hide()
    },
    assign(temp: NpcTemplate) {
      this.npc.AddTemplate(temp)
      this.$refs.templateDialog.hide()
    },
  },
})
