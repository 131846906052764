




















































import activePilot from '../../pilot_management/mixins/activePilot'

import vueMixins from '@/util/vueMixins'

export default vueMixins(activePilot).extend({
  name: 'pilot-nav-mode',
  methods: {
    pilotSheet() {
      this.$router.push({ name: 'pilot_sheet' })
    },
    mechHangar() {
      this.$router.push({ name: 'mech_hangar' })
    },
  },
})
