




































import Vue from 'vue'

export default Vue.extend({
  name: 'skill-select-item',
  props: {
    skill: {
      type: Object,
      required: true,
    },
    canAdd: {
      type: Boolean,
      required: true,
    },
    canRemove: {
      type: Boolean,
      required: true,
    },
    bonus: {
      type: Number,
      required: false,
      default: 0,
    },
  },
})
