








import Vue from 'vue'
import SkillBlock from './components/SkillBlock.vue'
import DtResourcesBlock from './components/DtResourcesBlock.vue'
import PilotLoadoutBlock from './components/PilotLoadoutBlock.vue'

export default Vue.extend({
  name: 'narrative-view',
  components: { SkillBlock, DtResourcesBlock, PilotLoadoutBlock },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
})
