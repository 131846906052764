

































































































































































































































































import { ActivationType, DiceRoller } from '@/class'
import Vue from 'vue'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'
import ActionTitlebar from '../../components/_ActionTitlebar.vue'

export default Vue.extend({
  name: 'skill-check-dialog',
  components: { ActionDetailExpander, ActionTitlebar },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    selected: null,
    hull: '',
    accuracy: 0,
    difficulty: 0,
    roll: '',
    rollString: '',
    rollResultString: '',
    rollAccuracyResults: '[]',
    complete: false,
    actionCost: false,
    actionFree: false,
    timer: 0,
  }),
  computed: {
    succeeded() {
      if (this.roll && this.hull) {
        this.runTimeout()
        return this.roll >= this.hull
      }
      return false
    },
    rollResultTooltip() {
      let str = this.rollString
      if (this.rollResultString) {
        str += `<div class="overline my-n2">Last Roll:</div><div class="caption ml-3">${this.rollResultString}`
        if (this.rollAccuracyResults.length)
          str += ` <span class="subtle--text">[${this.rollAccuracyResults.join(', ')}]</span>`
        str += '</div>'
      }
      return str
    },
    actionUsed() {
      return this.action.Used
    },
  },
  mounted() {
    if (!this.actionUsed) this.init()
  },
  methods: {
    confirmJockey() {
      this.mech.Pilot.State.CommitAction(
        this.action,
        this.actionFree ? ActivationType.Free : ActivationType.Full
      )
    },
    select(action) {
      return !action
    },
    rollSkill(): void {
      const roll = DiceRoller.rollToHit(this.mech.Pilot.Grit, this.accuracy, this.difficulty)
      this.rollResultString = `${roll.rawDieRoll} + ${roll.staticBonus}`
      if (roll.accuracyResult) {
        this.rollResultString += ` ${roll.accuracyResult > 0 ? '+' : '-'} ${Math.abs(
          roll.accuracyResult
        )}`
      }
      this.rollAccuracyResults = roll.rawAccuracyRolls
      this.roll = roll.total
    },
    reset() {
      this.mech.Pilot.State.UndoAction(
        this.action,
        this.actionFree ? ActivationType.Free : ActivationType.Full
      )
      this.init()
    },
    init() {
      this.accuracy = 0
      this.difficulty = 0
      this.hull = ''
      this.roll = ''
      this.rollString = ''
      this.rollResultString = ''
      this.rollAccuracyResults = '[]'
      this.complete = false
      this.actionCost = false
      this.actionFree = false
      this.timer = 0
    },
    show(): void {
      this.dialog = true
    },
    hide(): void {
      this.dialog = false
    },
  },
})
