var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"elevation-0 flavor-text",staticStyle:{"text-transform":"uppercase","background-color":"transparent","max-width":"100vw!important"},attrs:{"headers":_vm.shownHeaders,"items":_vm.items,"custom-sort":_vm.customSort,"item-key":"ID","height":_vm.tableHeight,"hide-default-footer":"","disable-pagination":"","calculate-widths":"","fixed-header":"","show-select":"","single-select":""},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"text-left",staticStyle:{"display":"grid"}},[_c('v-btn',{attrs:{"block":"","text":"","color":item.IsExotic ? 'exotic' : 'accent'},on:{"click":function($event){_vm.$refs[("modal_" + (item.ID))].show()}}},[_vm._v(" "+_vm._s(item.Name)+" ")])],1):_c('v-btn',{attrs:{"x-small":"","fab":"","color":"primary","dark":""},on:{"click":function($event){_vm.$refs[("modal_" + (item.ID))].show()}}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1),_c('cc-solo-dialog',{ref:("modal_" + (item.ID)),attrs:{"title":((item.Source) + " " + (item.Name)),"large":""}},[_c('cc-item-card',{attrs:{"item":item}})],1)]}},{key:"item.ItemType",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{domProps:{"innerHTML":_vm._s(item.Icon)}})]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [(_vm.spDisable && item.SP > _vm.sp && !_vm.spIgnore)?_c('span',{staticClass:"stat-text subtle--text"},[_vm._v(" "+_vm._s(item.Name)+" "),_c('cc-tooltip',{attrs:{"inline":"","content":"Equipment exceeds System Point capacity"}},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-alert")])],1)],1):_c('span',{class:("stat-text " + (item.IsExotic ? 'amber--text text-accent-4' : ''))},[_vm._v(" "+_vm._s(item.Name)+" ")])]}},{key:"item.SizeInt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Size)+" ")]}},{key:"item.Damage[0].Max",fn:function(ref){
var item = ref.item;
return [_c('cc-damage-element',{attrs:{"small":"","damage":item.Damage}})]}},{key:"item.Range[0].Max",fn:function(ref){
var item = ref.item;
return [_c('cc-range-element',{attrs:{"small":"","range":item.Range}})]}},{key:"item.Detail",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"accent"},on:{"click":function($event){_vm.$refs[("modal_" + (item.ID))].show()}}},[_vm._v(" mdi-information-outline ")]),_c('cc-search-result-modal',{ref:("modal_" + (item.ID)),attrs:{"item":item}})]}},{key:"item.Equip",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"text-left pl-0 ml-n6",staticStyle:{"display":"grid"}},[_c('v-btn',{attrs:{"color":"accent","small":"","tile":""},on:{"click":function($event){return _vm.$emit('equip', item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Equip "+_vm._s(item.Name)+" ")],1)],1):_c('v-btn',{attrs:{"color":"accent","small":"","tile":""},on:{"click":function($event){return _vm.$emit('equip', item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Equip ")],1)]}},{key:"item.Add",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"text-left pl-0 ml-n6",staticStyle:{"display":"grid"}},[_c('v-btn',{attrs:{"color":"accent","small":"","tile":""},on:{"click":function($event){return _vm.$emit('equip', item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add "+_vm._s(item.Name)+" ")],1)],1):_c('v-btn',{attrs:{"color":"accent","small":"","tile":""},on:{"click":function($event){return _vm.$emit('equip', item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add ")],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }