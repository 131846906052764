






































































import Vue from 'vue'
import { Auth } from '@aws-amplify/auth'
import { UserStore } from '@/store'
import { getModule } from 'vuex-module-decorators'

export default Vue.extend({
  name: 'sign-up',
  // components: { Auth },
  data: () => ({
    showError: false,
    error: '',
    loading: false,
    show: false,
    email: '',
    password: '',
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 6 || 'Min 6 characters',
      emailMatch: v =>
        !v || /^\w+([.-]?\w+)*(\+\w+([.-]?\w+)*)?@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(v) || 'E-mail must be valid',
    },
  }),
  computed: {
    submitOk() {
      return (
        /^\w+([.-]?\w+)*(\+\w+([.-]?\w+)*)?@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(this.email) && this.password.length >= 6
      )
    },
    test() {
      return process.env.VUE_APP_SOMEKEY
    },
  },
  methods: {
    async createAccount() {
      if (this.loading) return // debounce if already loading
      this.loading = true
      try {
        const userEmail = this.email.toLowerCase() // use safe const for auth
        this.email = userEmail
        const { user } = await Auth.signUp({
          username: userEmail,
          password: this.password,
          attributes: {
            email: userEmail,
          },
        })
        this.loading = false
        this.showError = false
        this.$emit('success', userEmail)
        // const userstore = getModule(UserStore, this.$store)
      } catch (error) {
        console.log('error signing up:', error)
        this.loading = false
        this.showError = true
        this.error = `${error.message}<br><div class='text-right'>${error.name}</div>`
      }
    },
  },
})
