








































































































































































import Vue from 'vue'
import StatblockItem from './StatblockItem.vue'
import HasePips from './HasePips.vue'

export default Vue.extend({
  name: 'attributes-block',
  components: { StatblockItem, HasePips },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    pilot: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
  computed: {
    small() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
})
