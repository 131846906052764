


























































































import Vue from 'vue'
import Component from 'vue-class-component'

import PromisifyFileReader from 'promisify-file-reader'
import { parseContentPack } from '@/io/ContentPackParser'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

import { IContentPack } from '@/interface'

import PackInfoCard from './components/PackInfoCard.vue'

@Component({
  components: { PackInfoCard },
})
export default class PackInstall extends Vue {
  private dataStore = getModule(CompendiumStore, this.$store)

  contentPack: IContentPack = null
  error: string = null

  async fileChange(file: HTMLInputElement) {
    this.contentPack = null
    this.error = null

    if (!file) return

    const fileData = await PromisifyFileReader.readAsBinaryString(file)
    try {
      this.contentPack = await parseContentPack(fileData)
    } catch (e) {
      this.error = e.message
    }
  }

  get packAlreadyInstalled() {
    return !!this.contentPack && this.dataStore.packAlreadyInstalled(this.contentPack.id)
  }

  get uninstalledDependencies() {
    if (!this.contentPack) return []
    const pack = this.contentPack as any
    const deps = pack.manifest ? pack.manifest.dependencies : []
    if (!deps) return []
    return deps.filter(dep => !this.dataStore.packAlreadyInstalled(dep.name, dep.version))
  }

  openLink(link) {
    window.open(link, '_blank')
  }

  parseVersion(version) {
    if (version.includes('*')) return 'any version'
    if (version.includes('=')) return version.replace('=', '')
    return version + ' or later'
  }

  public value = null
  public installing = false
  public done = false

  async install(): Promise<void> {
    if (this.done || this.installing) return
    this.$emit('start-load')
    this.installing = true
    this.contentPack.active = true
    await this.dataStore.installContentPack(this.contentPack)
    this.installing = false

    this.done = true
    setTimeout(() => {
      this.$emit('installed')
      this.contentPack = null
      this.error = null
      this.value = null
      this.done = false
      this.$emit('end-load')
    }, 500)
  }
}
