













































































































































































































import Vue from 'vue'
import SearchBar from '../SearchBar.vue'
import CompendiumPageButton from '../components/CompendiumPageButton.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'compendium-home',
  components: { SearchBar, CompendiumPageButton },
  computed: {
    hasBonds() {
      return getModule(CompendiumStore, this.$store).Bonds.length
    },
  },
})
