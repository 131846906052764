


















































































































































































import Vue from 'vue'
import EncounterSelector from './EncounterSelector.vue'
import EncounterPanel from './EncounterPanel.vue'
import MissionStepElement from './MissionStepElement.vue'
import { getModule } from 'vuex-module-decorators'
import { mission } from '@/io/Generators'
import { MissionStore } from '@/store'
import { Encounter } from '@/class'

export default Vue.extend({
  name: 'mission-card',
  components: { EncounterSelector, MissionStepElement, EncounterPanel },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    labels: {
      type: Array,
      required: false,
      default: () => [],
    },
    campaigns: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    mission() {
      const store = getModule(MissionStore, this.$store)
      return store.Missions.find(x => x.ID === this.id)
    },
  },
  created() {
    const store = getModule(MissionStore, this.$store)
    store.Missions.forEach(m => m.ValidateSteps())
  },
  methods: {
    randomName() {
      this.mission.Name = mission()
    },
    addEncounter(e: Encounter) {
      this.mission.AddEncounter(e)
      this.$refs.selectDialog.hide()
    },
  },
})
