






















































































































































import Vue from 'vue'
import { exportAll, importAll, clearAllData } from '@/io/BulkData'
import { saveFile } from '@/io/Dialog'

export default Vue.extend({
  name: 'backup-manager',
  props: {
    username: { type: String, required: true },
  },
  data: () => ({
    fileValue: null,
    loadBackupDialog: false,
    importConfirm: false,
    deleteLocal: false,
    deleteLocalConfirm: false,
    deleteCloud: false,
    deleteCloudConfirm: false,
  }),
  methods: {
    async dataExport() {
      const result = await exportAll()
      await saveFile(
        `CC_${new Date().toISOString().slice(0, 10)}.compcon`,
        JSON.stringify(result),
        'Save COMP/CON Archive'
      )
    },
    async importfile() {
      importAll(this.fileValue)
        .then(() => this.$notify('Data import successful', 'confirmation'))
        .catch(err => this.$notify(`ERROR: Unable to import: ${err}`, 'error'))
        .finally(() => {
          this.fileValue = null
          this.loadBackupDialog = false
          this.importConfirm = false
          this.$emit('change')
        })
    },
    async deleteAll(cloud) {
      clearAllData(cloud)
        .then(() => this.$notify('Data deleted', 'confirmation'))
        .catch(err => this.$notify(`ERROR: Unable to import: ${err}`, 'error'))
        .finally(() => {
          this.deleteCloud = false
          this.deleteCloudConfirm = false
          this.deleteLocal = false
          this.deleteLocalConfirm = false
          if (cloud) this.$emit('del-cloud')
          else this.$emit('del-local')
          this.$emit('change')
        })
    },
  },
})
