






































import Vue from 'vue'
import WeaponSlotCard from './weapon/_WeaponSlotCard.vue'
import CbMountMenu from './_CbMountMenu.vue'
import CbCard from './_CbCard.vue'
import ShLockCard from './_ShLockCard.vue'

export default Vue.extend({
  name: 'mount-block',
  components: { WeaponSlotCard, CbMountMenu, CbCard, ShLockCard },
  props: {
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    mount: {
      type: Object,
      required: true,
    },
    mech: {
      type: Object,
      required: true,
    },
    integrated: {
      type: Boolean,
    },
    impArm: {
      type: Boolean,
    },
    superheavy: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
  computed: {
    modifiable() {
      return this.mount.IsModifiable
    },
  },
})
