
















import Vue from 'vue'
export default Vue.extend({
  name: 'active-mode-loadout',
  props: {
    mech: {
      type: Object,
      required: true,
    },
    rest: {
      type: Boolean,
    },
  },
  data: () => ({
    showLoadout: true,
  }),
})
