







































































































import Vue from 'vue'
import Component from 'vue-class-component'
import CompendiumBrowser from '../components/CompendiumBrowser.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { NpcClass } from '@/class'
import { accentInclude } from '@/classes/utility/accent_fold'

@Component({
  components: { CompendiumBrowser },
})
export default class NpcClasses extends Vue {
  private tier = 1
  private search = ''
  private tableHeight = 450
  public headers = [
    { text: 'Class', align: 'left', value: 'Name' },
    { text: 'Role', align: 'left', value: 'Role' },
    { text: 'HP', align: 'left', value: 'HP' },
    { text: 'Armor', align: 'left', value: 'Armor' },
    { text: 'Struct.', align: 'left', value: 'Structure' },
    { text: 'HeatCap.', align: 'left', value: 'Heatcap' },
    { text: 'Stress', align: 'left', value: 'Stress' },
    { text: 'Evade', align: 'left', value: 'Evasion' },
    { text: 'E-Def', align: 'left', value: 'EDef' },
    { text: 'Speed', align: 'left', value: 'Speed' },
    { text: 'Sensor', align: 'left', value: 'Sensor' },
    { text: 'Save', align: 'left', value: 'Save' },
    { text: 'H/A/S/E', align: 'left', value: 'Hase', sortable: false },
  ]
  private compendium = getModule(CompendiumStore, this.$store)
  public get classes(): NpcClass[] {
    return this.compendium.NpcClasses
  }

  public get fItems(): NpcClass[] {
    if (this.search) return this.classes.filter(x => accentInclude(x.Name, this.search))
    return this.classes
  }

  private customSort(items, sortBy, sortDesc): NpcClass[] {
    if (sortBy === null || !sortBy.length) return items;

    return items.sort((a, b) => sortBy.map(val => val === "Evasion" ? "evade" : val.toLowerCase()).reduce((acc, val, idx) => {
      if (acc || val === "structure" || val === "stress") return acc;
      return ((val === "name" || val === "role") ? a[`_${val}`].localeCompare(b[`_${val}`]) : (a._stats._stats[val][this.tier-1] - b._stats._stats[val][this.tier-1])) * (sortDesc[idx] ? -1 : 1);
    }, 0));
  }

  onResize(): void {
    this.tableHeight = window.innerHeight - 160
  }

  mounted(): void {
    this.onResize()
  }
}
