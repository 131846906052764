
















import Vue from 'vue'

export default Vue.extend({
  name: 'cc-nav-item',
  props: {
    to: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
