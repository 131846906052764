
























































































import Vue from 'vue'
import PilotSelector from './PilotSelector.vue'
import { getModule } from 'vuex-module-decorators'
import { MissionStore } from '@/store'
import { Pilot, ActiveMission } from '@/class'

export default Vue.extend({
  name: 'mission-briefing',
  components: { PilotSelector },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    pilots: [],
  }),
  computed: {
    mission() {
      const store = getModule(MissionStore, this.$store)
      return store.Missions.find(x => x.ID === this.id)
    },
  },
  methods: {
    addPilot(pilot: Pilot) {
      this.pilots.push(pilot)
      this.$refs.pilotDialog.hide()
    },
    removePilot(pilot: Pilot) {
      const idx = this.pilots.findIndex(x => x.ID === pilot.ID)
      if (idx > -1) this.pilots.splice(idx, 1)
    },
    startMission() {
      const m = new ActiveMission(this.mission, this.pilots)
      const store = getModule(MissionStore, this.$store)
      store.addActiveMission(m)
      this.$router.push({ name: 'mission-runner', params: { id: m.ID } })
    },
  },
})
