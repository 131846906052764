
























import Vue from 'vue'
import TalentEmblem from './_TalentEmblem.vue'
export default Vue.extend({
  name: 'talent-micro',
  components: { TalentEmblem },
  props: {
    item: { type: Object, required: true },
    rank: { type: [Number, String], required: false, default: null },
  },
})
