
























import { Component, Prop, Mixins } from 'vue-property-decorator'
import GetColorMixin from '@/mixins/getColor'

@Component({ name: 'cc-titlebar' })
export default class CCTitlebar extends Mixins(GetColorMixin) {
  @Prop({ type: Boolean, required: false })
  readonly fixed?: boolean

  @Prop({ type: Boolean, required: false, default: true })
  readonly dark: boolean

  @Prop({ type: Boolean, default: true })
  readonly clipped: boolean

  @Prop({ type: String, required: false, default: 'primary' })
  readonly color: string

  @Prop({ type: String, required: false, default: '' })
  readonly icon: string

  @Prop({ type: Boolean, required: false })
  readonly large?: boolean
}
