









































































































































































































import Vue from 'vue'
import SyncManager from '@/ui/syncManager/SyncManager.vue'
import CloudContentManager from '@/ui/syncManager/CloudContentManager.vue'
import BackupManager from '@/ui/syncManager/BackupManager.vue'
import { Auth } from '@aws-amplify/auth'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'
import { UpdateUserData } from '@/cloud/user_sync'
import _ from 'lodash'

export default Vue.extend({
  name: 'auth-signed-in',
  components: { SyncManager, BackupManager, CloudContentManager },
  data: () => ({
    upgradeLoading: false,
    loading: false,
    showError: false,
    error: '',
    iid: '',
    oldpass: null,
    showOld: false,
    newpass: null,
    showNew: false,
    rules: {
      passLength: v => (v && v.length >= 6) || 'Minimum 6 characters',
    },
    authedUser: null,
  }),
  computed: {
    passMatch() {
      return () =>
        (this.oldpass && this.newpass && this.oldpass !== this.newpass) ||
        'Password must be different'
    },
    userProfile() {
      return getModule(UserStore, this.$store).UserProfile
    },
    isOnV2() {
      return this.userProfile && _.has(this.userProfile.SyncFrequency, 'cloudSync_v2')
    },
  },
  mounted() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.authedUser = user
      })
      .then(() => Auth.currentUserCredentials().then(res => (this.iid = res.identityId)))
      .catch(() => {
        this.$emit('set-state', 'sign-in')
      })
  },
  methods: {
    async load() {
      this.loading = true
      const userstore = getModule(UserStore, this.$store)
      Auth.currentAuthenticatedUser()
        .then(cognitoUser => {
          userstore.setAws({ cognitoUser })
        })
        .then(() => {
          this.loading = false
          this.$notify('Cloud Load Complete', 'success')
        })
        .catch(err => {
          console.error(err)
          this.loading = false
        })
    },
    changePass() {
      this.loading = true
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, this.oldpass, this.newpass)
        })
        .then(() => {
          this.loading = false
          this.showError = false
          this.$notify('Password Changed')
          this.oldpass = null
          this.newpass = null
        })
        .catch(err => {
          this.loading = false
          this.showError = true
          this.error = `${err.message}<br><div class='text-right'>${err.name}</div>`
        })
    },
    signOut() {
      Auth.signOut()
        .then(() => {
          this.$notify('Sign Out Complete')
          const store = getModule(UserStore, this.$store)
          store.setLoggedIn(false)
          this.$emit('set-state', 'sign-in')
        })
        .catch(err => {
          console.error(err)
        })
    },
    userUpdate() {
      UpdateUserData(this.userProfile).then(res => console.log(res))
    },
    copyIid() {
      navigator.clipboard
        .writeText(this.iid)
        .then(() => Vue.prototype.$notify('Cloud Identity ID copied to clipboard.', 'confirmation'))
        .catch(() => Vue.prototype.$notifyError('Unable to copy Cloud Identity ID '))
    },
    async v2Upgrade() {
      this.upgradeLoading = true
      try {
        await this.$refs.backup.dataExport()
        await this.$refs.lcps.syncAll(true)
        await this.$refs.sync.syncAll(true)
        await UpdateUserData(this.userProfile, true)
        this.$notify('Data successfully updated. Reloading.', 'success')
        setTimeout(() => {
          location.reload()
        }, 2000)
      } catch (error) {
        console.error(error)
        this.$notify('An error occured while syncing.', 'error')
      } finally {
        this.upgradeLoading = false
      }
    },
  },
})
