








































































import Vue from 'vue'
import ItemMenu from './_ItemMenu.vue'

export default Vue.extend({
  name: 'npc-item-card-base',
  components: { ItemMenu },
  props: {
    item: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
  },
  computed: {
    destructable() {
      return (
        !this.readonly &&
        (this.item.Feature.FeatureType === 'System' ||
          this.item.Feature.FeatureType === 'Weapon' ||
          this.item.Feature.FeatureType === 'Tech')
      )
    },
  },
})
