





































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { PilotManagementStore } from '@/store'
import { Pilot } from '@/class'
import { importData } from '@/io/Data'
import { PilotData } from '@/interface'

export default Vue.extend({
  name: 'pilot-selector',
  props: {
    selectedPilots: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    importID: '',
    importPilot: null,
    filePilot: null,
    error: false,
    errorText: '',
  }),
  computed: {
    pilots() {
      const store = getModule(PilotManagementStore, this.$store)
      return store.Pilots.filter(x => !this.selectedPilots.some(y => y.ID === x.ID))
    },
  },
  methods: {
    selectImport() {
      this.$emit('select', this.importPilot)
      this.importPilot = null
      this.filePilot = null
      this.importID = ''
    },
    async fileImport(file) {
      try {
        const fileData = await importData<PilotData>(file)
        this.filePilot = Pilot.Deserialize(fileData)
        this.filePilot.RenewID()
      } catch (error) {
        this.error = true
        this.errorText = error
      }
    },
    selectFile() {
      this.$emit('select', this.importPilot)
      this.filePilot = null
      this.importPilot = null
      this.importID = ''
    },
  },
})
