


























import Vue from 'vue'
import Component from 'vue-class-component'
import CompendiumBrowser from '../components/CompendiumBrowser.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore, UserStore } from '@/store'
import { ItemType, PilotArmor, PilotGear } from '@/class'
import { PilotWeapon } from '@/class'

@Component({
  components: { CompendiumBrowser },
})
export default class PilotGearBrowser extends Vue {
  public armor_headers = [
    { text: 'Item', align: 'left', value: 'Name' },
    { text: 'Armor', align: 'center', value: 'ArmorString' },
    { text: 'HP Bonus', align: 'center', value: 'HpString' },
    { text: 'E-Defense', align: 'center', value: 'EdefString' },
    { text: 'Evasion', align: 'center', value: 'EvasionString' },
    { text: 'Speed', align: 'center', value: 'SpeedString' },
  ]
  public weapon_headers = [
    { text: 'Item', align: 'left', value: 'Name' },
    { text: 'Range', align: 'left', value: 'Range[0].Max' },
    { text: 'Damage', align: 'left', value: 'Damage[0].Max' },
  ]
  public gear_headers = [
    { text: 'Item', align: 'left', value: 'Name' },
    { text: 'Uses', align: 'center', value: 'MaxUses' },
  ]

  // typing on these is wrong... look into fixing it
  private compendium = getModule(CompendiumStore, this.$store)
  private user = getModule(UserStore, this.$store).UserProfile
  get armor(): PilotArmor[] {
    let arr = this.compendium.PilotGear.filter(
      x => !x.IsHidden && x.ItemType === ItemType.PilotArmor
    )
    if (!this.user.GetView('showExotics')) arr = arr.filter(x => !x.IsExotic)
    return arr as PilotArmor[]
  }
  get weapons(): PilotWeapon[] {
    let arr = this.compendium.PilotGear.filter(
      x => !x.IsHidden && x.ItemType === ItemType.PilotWeapon
    )
    if (!this.user.GetView('showExotics')) arr = arr.filter(x => !x.IsExotic)
    return arr as PilotWeapon[]
  }
  get gear(): PilotGear[] {
    let arr = this.compendium.PilotGear.filter(
      x => !x.IsHidden && x.ItemType === ItemType.PilotGear
    )
    if (!this.user.GetView('showExotics')) arr = arr.filter(x => !x.IsExotic)
    return arr as PilotGear[]
  }
}
