
























































































































































































import Vue from 'vue'
import { name, callsign } from '@/io/Generators'
import TextEntryPopup from './components/TextEntryPopup.vue'

export default Vue.extend({
  name: 'identification-page',
  components: { TextEntryPopup },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
    quickstart: { type: Boolean },
  },

  methods: {
    randomCallsign() {
      this.$emit('set', { attr: 'Callsign', val: callsign() })
      this.$forceUpdate()
    },
    async randomName() {
      const generatedName = await name()
      this.$emit('set', { attr: 'Name', val: generatedName })
      this.$forceUpdate()
    },
  },
})
