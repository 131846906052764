














































































































import Vue from 'vue'
import { Reserve } from '@/class'
export default Vue.extend({
  name: 'gather-information',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    commit: false,
    skillRoll: '',
    details: '',
    choices: [
      'You leave clear evidence of your rummaging',
      'You have to dispatch someone along the way, or implicate someone innocent',
    ],
    choice: 0,
  }),
  methods: {
    addReserve() {
      const nr = new Reserve({
        id: 'reserve_gatherinfo',
        type: 'Resource',
        name: 'Information',
        label: '',
        description: '',
        resource_note: this.details,
        resource_name: '',
        resource_cost: '',
        used: false,
        consumable: true,
      })
      if (this.skillRoll < 10)
        nr.ResourceCost = 'Gathering this information has gotten you into immediate trouble'
      else if (this.skillRoll < 20) nr.ResourceCost = this.choices[this.choice]
      this.pilot.ReservesController.AddReserve(nr)
      this.close()
    },
    close() {
      this.commit = false
      this.skillRoll = ''
      this.details = ''
      this.choice = 0
      this.$emit('close')
    },
  },
})
