










































































import Vue from 'vue'
import EncounterPanel from './EncounterPanel.vue'
import { getModule } from 'vuex-module-decorators'
import { EncounterStore } from '@/store'

export default Vue.extend({
  name: 'encounter-selector',
  components: { EncounterPanel },
  data: () => ({
    encounters: [],
    side: 'Enemy',
    headers: [{ text: 'Name', value: 'Name', align: 'left' }],
    search: '',
  }),
  created() {
    const compendium = getModule(EncounterStore, this.$store)
    this.encounters = compendium.Encounters
  },
})
