














































import Vue from 'vue'
import PilotRegistrationCard from '../../PilotSheet/components/PilotRegistrationCard.vue'
import { getModule } from 'vuex-module-decorators'
import { PilotManagementStore } from '@/store'

export default Vue.extend({
  name: 'confirm-page',
  components: { PilotRegistrationCard },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
    quickstart: { type: Boolean },
  },
  data: () => ({
    default_callsign: '[NEW CALLSIGN]',
    default_name: 'New Pilot',
  }),
  computed: {
    pilotReady(): boolean {
      return (
        this.pilot.HasIdent &&
        this.pilot.SkillsController.HasFullSkills &&
        this.pilot.TalentsController.HasFullTalents &&
        this.pilot.MechSkillsController.HasFullHASE
      )
    },
  },
  methods: {
    savePilot() {
      this.pilot.cc_ver = Vue.prototype.version
      const store = getModule(PilotManagementStore, this.$store)
      this.pilot.Callsign = this.pilot.Callsign ? this.pilot.Callsign : this.default_callsign
      this.pilot.Name = this.pilot.Name ? this.pilot.Name : this.default_name
      store.addPilot(this.pilot)
      this.$emit('done')
    },
  },
})
