
















































import Vue from 'vue'
import { EquippableMount, MountType } from '@/class'

export default Vue.extend({
  name: 'sh-lock-dialog',
  props: {
    mech: {
      type: Object,
      required: true,
    },
    mount: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    availableMounts: [],
  }),
  mounted() {
    let candidates = this.mech.MechLoadoutController.ActiveLoadout.AllEquippableMounts(
      this.mech.Pilot.has('corebonus', 'cb_improved_armament'),
      false
    ) as EquippableMount[]

    if (this.superheavySelect) {
      candidates = this.mech.MechLoadoutController.ActiveLoadout.Mounts.filter(
        m => m.Type === MountType.Heavy
      )
    }

    this.availableMounts = candidates.filter(x => x.Name !== this.mount.Name)
  },
  computed: {
    superheavySelect() {
      return (
        this.mech.Pilot.has('corebonus', 'cb_superheavy_mounting') &&
        this.mech.MechLoadoutController.ActiveLoadout.Mounts.some(
          m => m.Type === MountType.Heavy
        ) &&
        this.mount.Type !== MountType.Heavy
      )
    },
  },
  methods: {
    show() {
      this.dialog = true
    },
    hide() {
      this.dialog = false
    },
  },
})
