







































import Vue from 'vue'
export default Vue.extend({
  name: 'ammo-case-inset',
  props: {
    level: { type: Number, required: true, default: 0 },
    uses: { type: Number, required: false, default: 0 },
    readonly: { type: Boolean },
  },
  data: () => ({
    selected: null,
    allAmmo: [
      {
        name: 'Standard',
        cost: 0,
        damage: '',
        effect: '',
      },
      {
        name: 'Thumper',
        cost: 1,
        damage: 'explosive',
        effect: 'This weapon gains Knockback 1 and deals Explosive damage.',
      },
      {
        name: 'Shock',
        cost: 1,
        damage: 'energy',
        effect:
          'This weapon deals Energy damage. Choose one character targeted by your attack; adjacent characters take 1 Energy AP, whether the result is a hit or miss.',
      },
      {
        name: 'Mag',
        cost: 1,
        damage: 'kinetic',
        effect: 'This weapon gains Arcing and deals Kinetic damage.',
      },
      {
        name: 'Hellfire',
        cost: 2,
        damage: 'energy',
        effect: 'This weapon deals Energy damage and deals any bonus damage as Burn.',
      },
      {
        name: 'Jager',
        cost: 2,
        damage: 'explosive',
        effect:
          'This weapon gains Knockback 2, deals Explosive damage, and one character hit by this weapon – your choice – must succeed on a Hull save or be knocked Prone.',
      },
      {
        name: 'Sabot',
        cost: 2,
        damage: 'kinetic',
        effect: 'This weapon gains AP and deals Kinetic damage.',
      },
    ],
  }),
  computed: {
    ammoItems() {
      if (this.level < 2) return this.allAmmo.slice(0, 4)
      return this.allAmmo
    },
  },
  created() {
    this.selected = this.allAmmo[0]
  },
  methods: {
    setSelection(ammo) {
      this.$emit('set-cost', ammo.cost)
      this.$emit('set-damage', ammo.damage)
    },
  },
})
