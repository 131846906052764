







































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'status-alert' })
export default class CCMechStatusAlert extends Vue {
  @Prop({ type: String, required: true })
  readonly type!: string
  @Prop({ type: Boolean })
  readonly criticalOnly?: boolean
  @Prop({ type: Boolean })
  readonly hideClear?: boolean

  get small() {
    return this.$vuetify.breakpoint.smAndDown
  }

  get show() {
    if (!this.criticalOnly) return true
    switch (this.type) {
      case 'overSP':
      case 'underSP':
      case 'unfinished':
      case 'unlicensed':
      case 'incompatiblemod':
        return false
      default:
        return true
    }
  }
  get icon() {
    switch (this.type) {
      case 'destroyed':
        return 'mdi-image-broken-variant'
        break
      case 'meltdown':
        return 'mdi-alert-outline'
        break
      case 'reactorDestroyed':
        return 'mdi-nuke'
        break
      case 'unshackled':
        return 'mdi-link-variant-off'
        break
      case 'overSP':
      case 'underSP':
        return 'cci-system'
        break
      case 'unfinished':
        return 'mdi-alert'
        break
      case 'unlicensed':
        return 'cci-license'
        break
      case 'incompatiblemod':
        return 'cci-status-downandout'
        break
      default:
        return ''
        break
    }
  }
  get color() {
    switch (this.type) {
      case 'destroyed':
      case 'cascading':
        return 'error'
        break
      case 'meltdown':
        return 'dangerzone'
        break
      case 'reactorDestroyed':
        return 'error darken-1'
        break
      case 'overSP':
      case 'unlicensed':
        return 'warning darken-1'
        break
      case 'incompatiblemod':
        return 'warning darken-1'
        break
      default:
        return 'grey darken-1'
        break
    }
  }
}
