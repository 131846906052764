









































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'
import { Auth } from '@aws-amplify/auth'
import { SetTheme } from '@/classes/utility/ThemeManager'

export default Vue.extend({
  name: 'auth-sign-in',
  data: () => ({
    email: null,
    password: null,
    show: false,
    error: null,
    showError: false,
    loading: false,
  }),
  methods: {
    async signIn() {
      if (this.loading) return // debounce if already loading
      this.loading = true
      const userEmail = this.email.trim() // use safe const for auth
      this.email = userEmail
      const userstore = getModule(UserStore, this.$store)
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      Auth.signIn(userEmail, this.password)
        .catch(error => {
          if (error.name === 'UserNotFoundException') {
            return Auth.signIn(userEmail.toLowerCase(), this.password)
          }
          throw error
        })
        .then(user => {
          localStorage.removeItem('user.config')
          userstore.setCognitoUser(user)
        })
        .then(() => {
          userstore.setAws({ cognitoUser: userstore.CognitoUser })
        })
        .then(() => {
          SetTheme(userstore.UserProfile.Theme, this.$vuetify)
          this.$notify('Login Complete', 'success')
          // this.$notify('Cloud Data Synchronized', 'success')
          this.loading = false
          this.showError = false
        })
        .then(() => self.$nextTick())
        .then(() => {
          this.$emit('set-state', 'signed-in')
        })
        .catch(error => {
          this.loading = false
          this.showError = true
          this.error = `${error.message}<br><div class='text-right'>${error.name}</div>`
        })
    },
    emailValid() {
      return this.email.indexOf('.') > -1 && this.email.indexOf('@') > -1
    },
  },
})
