








































import { ActivationType } from '@/class'
import Vue from 'vue'
export default Vue.extend({
  name: 'action-activation-buttons',
  props: {
    used: { type: Boolean },
    fulltech: { type: Boolean, default: false },
    action: {
      type: Object,
      required: true,
    },
    mech: {
      type: Object,
      required: true,
    },
  },
  computed: {
    disableCostActivate() {
      if (this.used && !this.fulltech) return true
      if (this.action.Activation === ActivationType.Protocol)
        return !this.mech.Pilot.State.IsProtocolAvailable
      let activationCost = 0
      if (
        this.action.Activation === ActivationType.Quick ||
        this.action.Activation === ActivationType.QuickTech
      )
        activationCost = 1
      else if (
        this.action.Activation === ActivationType.Full ||
        this.action.Activation === ActivationType.FullTech
      )
        activationCost = 2
      return this.mech.Pilot.State.Actions < activationCost
    },
  },
  methods: {
    select(free: boolean) {
      this.$emit('use', free)
    },
  },
})
