






import Vue from 'vue'
import bonusBase from './_bonusBase.vue'

export default Vue.extend({
  name: 'bonus-panel',
  components: { bonusBase },
  props: {
    bonus: {
      type: Object,
      required: true,
    },
  },
})
