











import Vue from 'vue'
export default Vue.extend({
  name: 'single-blank',
  props: {
    label: {
      type: String,
      required: true,
    },
    height: {
      type: [Number, String],
      required: false,
      default: '40',
    },
    cols: {
      type: [Number, String, Boolean],
      required: false,
      default: false,
    },
  },
})
