


























































































































import Vue from 'vue'
import TalentEmblem from './_TalentEmblem.vue'
import TalentRankContents from './_TalentRankContents.vue'

export default Vue.extend({
  name: 'talent-terse',
  components: { TalentEmblem, TalentRankContents },
  props: {
    hideLocked: { type: Boolean },
    item: { type: Object, required: true },
    canAdd: { type: Boolean },
    selectable: { type: Boolean },
    rank: { type: [Number, String], required: false, default: null },
  },
  data: () => ({
    showAll: false,
  }),
  computed: {
    showFull() {
      if (this.hideLocked) return this.showAll
      return true
    },
  },
  methods: {
    rankColor(n): string {
      if (!this.rank) return 'primary'
      const rank = parseInt(this.rank)
      if (n <= rank) return 'primary'
      if (this.selectable && n === rank + 1) return 'secondary'
      return 'grey'
    },
  },
})
