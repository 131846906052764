import { render, staticRenderFns } from "./_FrameCardContent.vue?vue&type=template&id=4c3cb759&"
import script from "./_FrameCardContent.vue?vue&type=script&lang=ts&"
export * from "./_FrameCardContent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
