










import Vue from 'vue'
import NarrativeView from '../sections/narrative/index.vue'
import InfoView from '../sections/info/index.vue'
import TacticalView from '../sections/tactical/index.vue'
import MechHangarView from '../sections/hangar/index.vue'
import BondsView from '../sections/bonds/index.vue'

export default Vue.extend({
  name: 'tabbed',
  components: { NarrativeView, InfoView, TacticalView, MechHangarView, BondsView },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  watch: {
    page(oldPage, newPage) {
      this.$vuetify.goTo(0, {
        duration: 0,
      })
    },
    mounted() {
      this.$vuetify.goTo(0, {
        duration: 0,
      })
    },
  },
})
