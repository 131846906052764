












































































































































import Vue from 'vue'

export default Vue.extend({
  name: 'tech-attack',
  props: {
    used: { type: Boolean },
    mech: { type: Object, required: true },
    action: { type: Object, required: true },
  },
  data: () => ({
    accuracy: 0,
    difficulty: 0,
    attackRoll: '',
    succeeded: false,
    failed: false,
    resetAttackRoll: false,
  }),
  watch: {
    used: {
      immediate: true,
      deep: true,
      handler: function(newval) {
        if (!newval) this.init()
      },
    },
  },
  methods: {
    init() {
      this.activated = false
      this.accuracy = 0
      this.difficulty = 0
      this.freeAction = false
      this.succeeded = false
      this.failed = false
      this.attackRoll = ''
      this.resetAttackRoll = false
      this.$nextTick(function() {
        this.resetAttackRoll = true
      })
    },
    registerTechRoll(roll) {
      Vue.set(this, 'attackRoll', roll)
      Vue.nextTick().then(() => this.$forceUpdate())
    },
    complete(success) {
      this.succeeded = success
      this.failed = !success
      this.$emit('techAttackComplete', this.succeeded)
    },
  },
})
