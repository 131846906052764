













import NarrativeFooter from './footers/NarrativeFooter.vue'
import CombatFooter from './footers/CombatFooter.vue'
import RestFooter from './footers/RestFooter.vue'
import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'

export default vueMixins(activePilot).extend({
  name: 'turn-footer',
  components: { NarrativeFooter, CombatFooter, RestFooter },
})
