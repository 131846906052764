














































































import Vue from 'vue'
export default Vue.extend({
  name: 'selector-split-view',
  props: {
    items: {
      type: Array,
      required: true,
    },
    equipmentAdd: { type: Boolean },
    spDisable: { type: Boolean },
    spIgnore: { type: Boolean },
    sp: { type: Number, required: false, default: 0 },
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    selectedItem() {
      return this.items.find(x => x.ID === this.selected)
    },
  },
})
