
































































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { ContentPack } from '@/class'
import { IContentPack, IContentPackManifest } from '@/interface'
import _ from 'lodash'
import { PropType } from 'vue'

export default Vue.extend({
  props: {
    pack: { type: Object as PropType<IContentPack | ContentPack>, required: true },
  },
  data: () => ({
    humanReadableMap: {
      manufacturers: ['manufacturer', 'manufacturers'],
      coreBonuses: ['core bonus', 'core bonuses'],
      frames: ['frame', 'frames'],
      weapons: ['weapon', 'weapons'],
      systems: ['system', 'systems'],
      mods: ['weapon mod', 'weapon mods'],
      pilotGear: ['pilot gear item', 'pilot gear items'],
      backgrounds: ['background', 'backgrounds'],
      skills: ['skill', 'skills'],
      bonds: ['bond', 'bonds'],
      reserves: ['reserve', 'reserves'],
      talents: ['pilot talent', 'pilot talents'],
      tags: ['equipment tag', 'equipment tags'],
      npcClasses: ['NPC class', 'NPC classes'],
      npcFeatures: ['NPC feature', 'NPC features'],
      npcTemplates: ['NPC template', 'NPC templates'],
      actions: ['Player action', 'Player actions'],
      statuses: ['Status/Condition', 'Statuses/Conditions'],
      environments: ['Combat Environment', 'Combat Environments'],
      factions: ['faction', 'factions'],
      sitreps: ['sitrep', 'sitreps'],
      tables: ['Data Table', 'Data Tables'],
    },
  }),

  computed: {
    manifest() {
      return (this.pack as IContentPack).manifest
        ? (this.pack as IContentPack).manifest
        : (this.pack as ContentPack).Manifest
    },
    packContents() {
      const data = (this.pack as IContentPack).data
        ? (this.pack as IContentPack).data
        : (this.pack as ContentPack).Data
      return _.toPairs(data)
        .map(([key, value]: [string, object[]]) => {
          const count = value.length
          return [key, count]
        })
        .filter(([, count]) => Number(count) > 0)
        .map(([key, count]) => {
          const pair = this.humanReadableMap[key]
          if (!pair) return { count, name: `${key}--NOT--HUMANIZED` }
          const [singular, plural]: [string, string] = pair
          return { count, name: Number(count) > 1 ? plural : singular }
        })
    },
    packDependencies() {
      const manifest = (
        (this.pack as any).manifest
          ? (this.pack as IContentPack).manifest
          : (this.pack as ContentPack).Manifest
      ) as IContentPackManifest

      return manifest.dependencies ? manifest.dependencies : []
    },
  },
  methods: {
    parseVersion(version) {
      if (version.includes('*')) return 'any version'
      if (version.includes('=')) return version.replace('=', '')
      return version + ' or later'
    },
    d(dep: any) {
      return {
        name: dep.name,
        version: this.parseVersion(dep.version),
        link: dep.link,
        installed: getModule(CompendiumStore, this.$store).packAlreadyInstalled(
          dep.name,
          dep.version
        ),
      }
    },
  },
})
