






































































































































































import Vue from 'vue'

export default Vue.extend({
  name: 'equipment-options-menu',
  props: {
    item: {
      type: Object,
      required: true,
    },
    readonly: Boolean,
    active: Boolean,
  },
  methods: {
    save(prop, newName) {
      this.$set(this.item, prop, newName)
    },
  },
})
