




































































































































































































































import Vue from 'vue'
import SyncItemMenu from './SyncItemMenu.vue'
import {
  ListCloudItems,
  ProcessItemsList,
  SyncItem,
  DeleteForever,
  GetLocalItem,
  Overwrite,
  FlagCloudDelete,
  FlagCloudRestore,
  AutoSyncAll,
  AutoSyncRemotes,
  RemoteSyncItem,
} from '@/cloud/item_sync'
import { ICloudSyncable } from '@/classes/components'
import { Pilot } from '@/classes/pilot/Pilot'
import sleep from '@/util/sleep'
import { SaveAllLocalUpdates } from '@/io/BulkData'

export default Vue.extend({
  name: 'sync-manager',
  components: { SyncItemMenu },
  data: () => ({
    loading: false,
    overwriteCloud: false,
    overwriteLocal: false,
    confirmOverwriteCloud: false,
    confirmOverwriteLocal: false,
    items: [],
    cloudItemTypes: ['Active Mission', 'Mission', 'Encounter', 'NPC', 'Pilot'],
  }),
  async mounted() {
    this.fetch()
  },
  computed: {
    selectedItems() {
      return this.items.filter(x => x.selected)
    },
  },
  methods: {
    selectAll(type) {
      this.itemsByType(type).forEach(i => {
        this.$set(i, 'selected', true)
      })
    },
    deselectAll(type) {
      this.itemsByType(type).forEach(i => {
        this.$set(i, 'selected', false)
      })
    },
    itemsByType(type) {
      return this.items.filter(x => x.itemType === type)
    },
    callsign(item) {
      if (item.itemType !== 'Pilot') return ''
      const p = GetLocalItem(item) as Pilot
      if (p && p.Callsign) return `${p.Callsign} //`
    },
    isAtLatest(item) {
      if (!item.lastModifiedCloud || !item.lastModifiedLocal) return false
      const sDiff =
        Math.abs(
          new Date(item.lastModifiedCloud).valueOf() - new Date(item.lastModifiedLocal).valueOf()
        ) / 1000
      return sDiff < 15
    },
    async fetch() {
      this.loading = true
      ListCloudItems()
        .then(res => {
          this.items = ProcessItemsList(res)
        })
        .finally(() => (this.loading = false))
    },
    async syncSingle(item) {
      this.loading = true
      SyncItem(item)
        .then(() => this.fetch())
        .then(() => this.$notify('Sync successful', 'success'))
        .catch(() =>
          this.$notify(
            'An error occured while syncing. You may be missing one or more required LCPs.',
            'error'
          )
        )
    },
    async syncSelected() {
      this.loading = true
      Promise.all(this.selectedItems.map(item => SyncItem(item, true)))
        .then(() => SaveAllLocalUpdates())
        .then(() => this.fetch())
        .then(() =>
          this.$notify(`Synced ${this.selectedItems.length} items successfully`, 'success')
        )
        .catch(() =>
          this.$notify(
            'An error occured while syncing. You may be missing one or more required LCPs.',
            'error'
          )
        )
    },
    async overwriteSingle(item, source, dest) {
      this.loading = true
      Overwrite(item, source, dest)
        .then(() => this.fetch())
        .then(() => this.$notify('Overwrite successful', 'success'))
        .catch(() => this.$notify('An error occured while overwriting.', 'error'))
    },
    async overwriteSelected(source, dest) {
      this.loading = true
      Promise.all(this.selectedItems.map(item => Overwrite(item, source, dest, true)))
        .then(() => SaveAllLocalUpdates())
        .then(() => this.fetch())
        .then(() =>
          this.$notify(`Replaced ${this.selectedItems.length} items successfully`, 'success')
        )
        .catch(() => this.$notify('An error occured while overwriting.', 'error'))
    },
    async undelete(item) {
      const local = GetLocalItem(item) as ICloudSyncable
      if (local) {
        local.SaveController.restore()
        this.fetch()
      } else FlagCloudRestore(item).then(() => this.fetch())
    },
    async flagDelete(item) {
      const local = GetLocalItem(item) as ICloudSyncable
      if (local) {
        local.SaveController.delete()
        this.fetch()
      } else FlagCloudDelete(item).then(() => this.fetch())
    },
    deleteForever(item) {
      DeleteForever(item)
        .then(() => this.fetch())
        .then(() => this.$notify('Delete successful', 'success'))
        .catch(() =>
          this.$notify('An error occured while attempting to delete this record.', 'error')
        )
    },
    syncAll(hideAlert?: boolean) {
      this.loading = true
      AutoSyncAll()
        .then(() => AutoSyncRemotes())
        .then(() => sleep(500))
        .then(() => this.fetch())
        .then(() => {
          if (!hideAlert) this.$notify(`Synced ${this.items.length} items successfully`, 'success')
        })
        .catch(() => {
          if (!hideAlert) this.$notify('An error occured while syncing.', 'error')
        })
    },
    async remoteUpdate(item) {
      const local = GetLocalItem(item) as ICloudSyncable
      try {
        RemoteSyncItem(local)
        this.$notify('Pilot synced to remote', 'success')
        this.fetch()
      } catch (error) {
        console.error(error)
        this.$notify('An error occurred while attempting to download remote data', 'error')
      }
    },
  },
})
