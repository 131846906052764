




























import { Vue, Component, Prop } from 'vue-property-decorator'
import ClassCard from '@/features/encounters/npc/new/ClassCard.vue'

@Component({name: 'cc-search-result-modal', components: { ClassCard }})
export default class CCSearchResultModal extends Vue {
  @Prop({type: Object, required: true})
  readonly item

  dialog = false

  show() {
    this.dialog = true
  }
}
