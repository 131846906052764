















import Vue from 'vue'

export default Vue.extend({
  name: 'panel-view',
  methods: {
    resetScroll() {
      document.getElementById('scrollTarget').scrollTop = 0
    },
  },
})
