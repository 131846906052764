










import Vue from 'vue'
import SidebarArrayView from '../components/SidebarArrayView.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'skills',
  components: { SidebarArrayView },
  data: () => ({
    skills: [],
  }),
  async created() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.skills = await compendium.Skills.filter(x => x)
  },
})
