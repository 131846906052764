



































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Tag } from '@/class'

@Component({ name: 'cc-tag' })
export default class CCExtendedTag extends Vue {
  @Prop({ type: Object, required: true })
  readonly tag: Tag
  @Prop({ type: String, required: false, default: 'active' })
  readonly color: string
  @Prop({ type: Number, required: false, default: 0 })
  readonly bonus?: number
}
