




import Vue from 'vue'
export default Vue.extend({
  name: 'special-thanks-badge',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
})
