












































































import Vue from 'vue'

export default Vue.extend({
  name: 'bonds-print',
  props: {
    bc: {
      type: Object,
      required: true,
    },
  },
})
