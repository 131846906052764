



























import { Vue, Component, Prop } from 'vue-property-decorator'
import { glossary } from '@massif/lancer-data'

@Component({ name: 'cc-statblock-panel' })
export default class CCStatblockPanel extends Vue {
  @Prop({ type: String, required: true })
  readonly name!: string

  @Prop({ type: String, required: true })
  readonly icon!: string

  @Prop({ type: [String, Number], required: true })
  readonly value!: string | number

  @Prop({ type: [String, Number], required: false })
  readonly cols!: string | number

  @Prop({ type: Boolean })
  readonly inline: boolean

  glossary(name: string): string {
    const n = glossary.find(x => x.name.toLowerCase() === name.toLowerCase())
    return n ? n.description : 'MISSING'
  }
}
