





















































































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'

@Component({ name: 'tick-bar' })
export default class CCTickBar extends Vue {
  created(): void {
    this.lock = true
    if (!this.readonly) {
      this.model = this.current > this.max ? this.max : this.current
    } else this.model = this.max
  }

  mounted(): void {
    this.$nextTick(() => {
      this.lock = false
    })
  }

  @Prop({ type: String, required: false, default: '' })
  readonly label: string

  @Prop({ type: String, required: false, default: 'start' })
  readonly justify: string
  @Prop({ type: Boolean })
  readonly hideButtons?: boolean
  @Prop({ type: Boolean })
  readonly small?: boolean
  @Prop({ type: Boolean })
  readonly large?: boolean
  @Prop({ type: String, required: false, default: 'mdi-hexagon-outline' })
  readonly emptyIcon: string
  @Prop({ type: String, required: false, default: 'mdi-hexagon' })
  readonly fullIcon: string
  @Prop({ type: String, required: false, default: 'accent' })
  readonly color: string
  @Prop({ type: String, required: false, default: 'panel' })
  readonly bgColor: string
  @Prop({ type: Boolean })
  readonly noPips?: boolean
  @Prop({ type: Boolean })
  readonly hideMax?: boolean
  @Prop({ type: Boolean })
  readonly hideValues?: boolean

  @Prop({ type: Boolean })
  readonly readonly?: boolean
  @Prop({ type: Boolean })
  readonly noInput?: boolean
  @Prop({ type: Boolean })
  readonly flipInput?: boolean
  @Prop({ type: Boolean })
  readonly clearable?: boolean
  @Prop({ type: Boolean })
  readonly numberOnly?: boolean

  @Prop({ type: Number, required: true })
  readonly current!: number
  @Prop({ type: Number, required: true })
  readonly max!: number
  @Prop({ type: [Number, String], required: false })
  readonly maxLength?: number

  model = 0
  lock = true
  inputting = false
  myInput = ''

  @Watch('model')
  onModelChange(val: number): void {
    if (!this.lock && !isNaN(val)) {
      this.$emit('update', val)
    }
  }

  onInputChange(e): void {
    const newVal = e.target.value
    if (newVal.match(/^[+-\d]\d*$/) || newVal === '') this.myInput = newVal
    else e.target.value = this.myInput
  }

  @Ref('pipinput') readonly pipinput: HTMLInputElement
  startInputting(): void {
    this.inputting = true
    this.$nextTick(() => {
      this.pipinput.focus()
    })
  }

  get maxExceeded(): boolean {
    if (this.numberOnly) return true
    if (!this.maxLength) return false
    return this.max > this.maxLength
  }

  sendInput(): void {
    const thisInput = this.myInput
    if (!thisInput.match(/\d/)) return

    this.inputting = false

    let preResult = this.current

    if (thisInput === '') return
    else if (thisInput.startsWith('+')) {
      preResult += parseInt(thisInput.substr(1))
    } else if (thisInput.startsWith('-')) {
      preResult -= parseInt(thisInput.substr(1))
    } else {
      preResult = parseInt(thisInput)
    }

    this.$emit('update', preResult)
    this.myInput = ''
  }

  cancelInput(): void {
    this.inputting = false
    this.myInput = ''
  }
}
