










import Vue from 'vue'
export default Vue.extend({
  name: 'simple-attribute',
  props: {
    attr: {
      type: [String, Number],
      required: true,
    },
    val: {
      type: [String, Number],
      required: true,
    },
  },
})
