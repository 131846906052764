

















import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'autoupdater',
  props: ['brew'],
  computed: {
    lcps() {
      return getModule(CompendiumStore, this.$store).ContentPacks
    },
    normalizedBrew() {
      if (this.brew.LcpName) return this.brew
      const arr = this.brew.split(' @ ')
      return {
        LcpName: arr[0],
        LcpVersion: arr[1],
      }
    },
    packByName() {
      return this.lcps.find(x => x.Name.toLowerCase() === this.normalizedBrew.LcpName.toLowerCase())
    },
    versionAnalysis() {
      if (!this.packByName) return 'err'
      let vBrew = parseInt(this.normalizedBrew.LcpVersion.split('.').join(''))
      if (isNaN(vBrew)) return 'err'
      let vPack = parseInt(this.packByName.Version.split('.').join(''))
      if (isNaN(vPack)) return 'err'

      if (vBrew < vPack) return 'brew outdated'
      return 'pack outdated'
    },
  },
})
