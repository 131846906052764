


























import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import { NavStore } from '@/store'

@Component
export default class OptionsLog extends Vue {
  get errors() {
    return getModule(NavStore, this.$store).Errors
  }

  dateFormat(date: Date) {
    return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
      .getDate()
      .toString()
      .padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
  }

  onCopy(e, error) {
    const text =
      '```\n' +
      (error.component ? `Vue error at [${error.component}]\n` : '') +
      window.getSelection().toString() +
      '```'
    e.clipboardData.setData('text/plain', text)
    e.preventDefault()
  }
}
