
























































































































import Vue from 'vue'
import { OrgType } from '@/class'

export default Vue.extend({
  name: 'cc-org-item',
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    orgTypes() {
      return Object.keys(OrgType)
        .map(k => OrgType[k as string])
        .sort() as OrgType[]
    },
  },
  methods: {
    remove() {
      this.$emit('remove')
      this.dialog = false
    },
  },
})
