



























































import Vue from 'vue'
import PrintOptionSelect from './PrintOptionSelect.vue'

export default Vue.extend({
  name: 'print-options-dialog',
  components: { PrintOptionSelect },
  data: () => ({
    options: null,
    layoutOptions: [
      { text: 'Graphic/Portrait', icon: 'mdi-file' },
      { text: 'Graphic/Landscape', icon: 'mdi-note' },
      { text: 'Text-Only/Portrait', icon: 'mdi-file-document-outline' },
      { text: 'Text-Only/Landscape', icon: 'mdi-file-document-outline-outline' },
    ],
    fontSizeOptions: [
      { text: 'Small', icon: 'mdi-format-font-size-decrease' },
      { text: 'Regular', icon: 'mdi-format-text-variant' },
      { text: 'Large', icon: 'mdi-format-font-size-increase' },
    ],
    trackableStatOptions: [
      { text: 'Numbers', icon: 'mdi-numeric' },
      { text: 'Pips', icon: 'mdi-checkbox-multiple-marked-outline' },
    ],
    pilotIncludeOptions: [
      { text: 'Pilot Portrait' },
      { text: 'Appearance Notes' },
      { text: 'Extended Info' },
      { text: 'Pilot Biography' },
      { text: 'Item Notes' },
      { text: 'Pilot Notes' },
      { text: 'Portrait Sketch Panel' },
      { text: 'Blank Lined Panel' },
      { text: 'Blank Unlined Panel' },
      { text: 'Extra Equipment Space' },
      { text: 'Blank Panel: Top' },
      { text: 'Blank Panel: Middle' },
      { text: 'Blank Panel: Bottom' },
      { text: 'Append Unlined Section' },
      { text: 'Append Lined Section' },
    ],
    mechIncludeOptions: [
      { text: 'Mech Image' },
      { text: 'Mech Notes' },
      { text: 'Item Notes' },
      { text: 'Extra Mount Panel' },
      { text: 'Extra System Space' },
      { text: 'Sketch Panel' },
      { text: 'Blank Lined Panel' },
      { text: 'Blank Unlined Panel' },
      { text: 'Append Unlined Section' },
      { text: 'Append Lined Section' },
    ],
    extraOptions: [
      { text: 'Relevant Tag Info' },
      { text: 'Combat Quick Reference' },
      { text: 'Downtime Quick Reference' },
    ],
  }),
  methods: {
    show() {
      this.$refs.dialog.show()
    },
    hide() {
      this.$refs.dialog.hide()
    },
  },
})
