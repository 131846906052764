



















































































































































































































































































































































































































































































































































































import sleep from '@/util/sleep'
import { Mech, MechLoadout } from '@/class'
import MechSelectButton from '../components/MechSelectButton.vue'
import PipLayout from './PipLayout.vue'
import ActiveModeLoadout from './ActiveModeLoadout.vue'
import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'
import DestroyedAlert from '../components/DestroyedAlert.vue'

export default vueMixins(activePilot).extend({
  name: 'mech-block',
  components: {
    MechSelectButton,
    PipLayout,
    ActiveModeLoadout,
    DestroyedAlert,
  },
  data: () => ({
    showTalents: true,
    showCBs: true,
    showCounters: true,
    tabs: 0,
    burn: 0,
    structRolledOver: false,
    stressRolledOver: false,
    repairMenu: false,
  }),
  computed: {
    mech(): Mech {
      return this.pilot.ActiveMech || null
    },
    state() {
      return this.pilot.State
    },
    resistances() {
      return [
        { name: 'Kinetic', color: 'kinetic' },
        { name: 'Energy', color: 'energy' },
        { name: 'Explosive', color: 'explosive' },
        { name: 'Heat', color: 'heat' },
        { name: 'Burn', color: 'burn' },
        { name: 'All', color: 'variable' },
        { name: 'Next Attack', color: 'overcharge' },
      ]
    },
    coreActivator() {
      return this.mech.FeatureController.Actions.find(x => x.ID === 'core_active_activate')
    },
    loadout(): MechLoadout {
      return this.mech.MechLoadoutController.ActiveLoadout
    },
    statuses(): string[] {
      return this.$store.getters.getItemCollection('Statuses').filter(x => x.type === 'Status')
    },
    conditions(): string[] {
      return this.$store.getters.getItemCollection('Statuses').filter(x => x.type === 'Condition')
    },
    hpResistance(): boolean {
      if (this.mech.Resistances.length === 1 && this.mech.Resistances[0] === 'Heat') return false
      return !!this.mech.Resistances.length
    },
    hpColor(): string {
      if (this.mech.Resistances.length) {
        if (this.mech.Resistances.length === 1) {
          const c = this.resistances
            .find(x => x.name === this.mech.Resistances[0])
            .name.toLowerCase()
          if (c === 'heat') return 'heat'
          return 'overcharge'
        }
        return 'variable--damage'
      }
      return 'hp'
    },
  },
  watch: {
    'mech.CurrentStructure': {
      async handler(newVal: number, oldVal: number) {
        if (newVal === 0) return
        if (newVal < oldVal) {
          this.structRolledOver = true
          await sleep(500)
          this.structRolledOver = false
          this.$refs.structureTable.show()
        } else if (newVal > 0 && this.mech.Destroyed) {
          this.mech.Destroyed = false
        }
      },
    },
    'mech.CurrentStress': {
      async handler(newVal: number, oldVal: number) {
        if (newVal === 0) return
        if (newVal < oldVal) {
          this.stressRolledOver = true
          await sleep(500)
          this.stressRolledOver = false
          this.$refs.stressTable.show()
        } else if (newVal > 0 && this.mech.ReactorDestroyed) {
          this.mech.ReactorDestroyed = false
        }
      },
    },
    'mech.Destroyed': {
      async handler(newVal: boolean) {
        if (newVal) {
          this.showTalents = false
          this.showCBs = false
          this.showCounters = false
        } else {
          this.showTalents = true
          this.showCBs = true
          this.showCounters = true
        }
      },
    },
    'mech.ReactorDestroyed': {
      async handler(newVal: boolean) {
        if (newVal) {
          this.showTalents = false
          this.showCBs = false
          this.showCounters = false
        } else {
          this.showTalents = true
          this.showCBs = true
          this.showCounters = true
        }
      },
    },
  },
  methods: {
    expandAll(len: number, key: string, expand: boolean) {
      for (let i = 0; i < len; i++) {
        const k = key + i
        if (this.$refs[k]) this.$refs[k][0].collapsed = expand
      }
    },
  },
})
