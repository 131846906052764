
























































import Vue from 'vue'
import { Reserve } from '@/class'

export default Vue.extend({
  name: 'custom-reserve-panel',
  data: () => ({
    customType: 'Resource',
    customName: '',
    details: '',
  }),
  methods: {
    add() {
      const nr = new Reserve({
        id: 'reserve_custom',
        type: this.customType,
        name: this.customName,
        label: this.customName,
        description: this.details,
        resource_name: '',
        resource_note: '',
        resource_cost: '',
        used: false,
        consumable: true,
      })
      this.clear()
      this.$emit('add', nr)
    },
    clear() {
      this.customType = 'Resources'
      this.customName = ''
      this.details = ''
    },
  },
})
