













import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'cc-simple-select', })
export default class CCSimpleSelect extends Vue {

  @Prop({ type: Array, required: true, validator: (item) => item.text && item.text.toString })
  readonly items!: { 
    text: string | number | object
    value: string | number | object
  }[]

}
