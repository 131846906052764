

































import Vue from 'vue'

export default Vue.extend({
  name: 'selector',

  props: {
    title: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: '90vh',
    },
    success: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    floating: false,
  }),
  mounted() {
    this.floating = false
    window.addEventListener(
      'scroll',
      () => {
        if (!this.$refs.float || !this.$refs.content) return
        const floatY = this.$refs['float'].getBoundingClientRect().top
        const containerY = this.$refs['content'].getBoundingClientRect().top
        if (floatY && floatY <= 30) this.floating = true
        else if (floatY < containerY) this.floating = false
      },
      true
    )
  },
  beforeDestroy() {
    this.floating = false
    window.removeEventListener('scroll', null, false)
  },
})
