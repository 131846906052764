







































































import Vue from "vue";
import MechCard from "./components/MechCard.vue";
import MechListItem from "./components/MechListItem.vue";
import MechTable from "./components/MechTable.vue";
import NewMechMenu from "./components/NewMechMenu.vue";
import { getModule } from "vuex-module-decorators";
import { UserStore, PilotManagementStore } from "@/store";
import { Pilot } from "@/class";
import { UserProfile } from "@/user";

export default Vue.extend({
  name: "mech-hangar-view",
  components: { MechCard, MechListItem, MechTable, NewMechMenu },
  props: {
    pilot: {
      type: Pilot,
      required: true,
    },
  },
  computed: {
    profile(): UserProfile {
      const store = getModule(UserStore, this.$store);
      return store.UserProfile;
    },
    getView() {
      if (this.profile) return this.profile.GetView("hangar");
      return "cards";
    },
  },
  methods: {
    toMechSheet(mech) {
      const store = getModule(PilotManagementStore, this.$store);
      store.setLoadedMech(mech.ID);
      this.$router.push(`../mech/${mech.ID}`);
    },
  },
});
