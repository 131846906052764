











































































































import Vue from 'vue'
import SkillSelectItem from './components/_SkillSelectItem.vue'
import AddCustomSkill from './components/_AddCustomSkill.vue'
import MissingItem from './components/_MissingItem.vue'
import Selector from './components/_SelectorBase.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { Rules, Pilot } from '@/class'
import { rules } from '@massif/lancer-data'

export default Vue.extend({
  name: 'skill-selector',
  components: { Selector, SkillSelectItem, AddCustomSkill, MissingItem },
  props: {
    pilot: Pilot,
    levelUp: Boolean,
  },
  data: () => ({
    staticSkills: [],
    headers: [],
  }),
  computed: {
    skills() {
      const cs = this.pilot.SkillsController.Skills.filter(x => x.IsCustom)
      if (cs.length) return { ...this.staticSkills, Custom: cs.map(x => x.Skill) }
      return this.staticSkills
    },
    newPilot(): boolean {
      return this.pilot.Level === 0
    },
    selectedMin(): number {
      return Rules.MinimumPilotSkills
    },
    enoughSelections(): boolean {
      return !(this.pilot.SkillsController.Skills.length < this.selectedMin)
    },
    selectionComplete(): boolean {
      return (this.newPilot || this.levelUp) && !this.pilot.SkillsController.IsMissingSkills
    },
  },
  watch: {
    selectionComplete(bool) {
      if (bool) window.scrollTo(0, document.body.scrollHeight)
    },
  },
  created() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.staticSkills = this.$_.groupBy(compendium.Skills, 'Family')
    this.headers = rules.skill_headers
  },
  methods: {
    scroll(id) {
      if (this.levelUp)
        this.$vuetify.goTo(`#skill_${id}`, {
          duration: 150,
          easing: 'easeInOutQuad',
          offset: 25,
        })
      else
        this.$vuetify.goTo(`#skill_${id}`, {
          duration: 150,
          easing: 'easeInOutQuad',
          offset: 25,
          container: '.v-dialog--active',
        })
    },
  },
})
