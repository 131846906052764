










































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Counter } from '@/class'

@Component({
  props: {
    counterData: {
      type: Object,
      required: true,
    },
    saveData: {
      type: Array,
      required: true,
    },
  },
  watch: {
    counter: {
      handler(val: Counter) {
        this.$emit('update', val)
      },
      deep: true,
    },
  },
})
export default class CounterComponent extends Vue {
  public counter: Counter = null

  created(): void {
    this.counter = new Counter(this.$props.counterData)

    const data = this.$props.saveData.find(data => data.id === this.counter.ID)
    if (data) this.counter.LoadData(data)
  }

  public dirty = false
  onInput(): void {
    this.dirty = true
  }

  onInputEnterOrLeave(e: FocusEvent | InputEvent): void {
    const element = e.target as HTMLInputElement

    const val = parseInt(element.value)
    this.counter.Set(val)
    element.value = this.counter.Value.toString()
    this.dirty = false
  }
}
