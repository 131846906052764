











































































import Vue from 'vue'
import SystemSlotCard from './_SystemSlotCard.vue'
import ModEquippedCard from './_ModEquippedCard.vue'
import draggable from 'vuedraggable'

export default Vue.extend({
  name: 'systems-block',
  components: { SystemSlotCard, ModEquippedCard, draggable },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
    },
  },
  watch: {
    activeSystems() {
      this.systems = this.activeSystems
    },
  },
  computed: {
    moddedWeapons() {
      return this.mech.MechLoadoutController.ActiveLoadout.Weapons.filter(x => x.Mod)
    },
    isTouch() {
      if ('ontouchstart' in document.documentElement) {
        return true
      } else {
        return false
      }
    },
    activeSystems() {
      return this.mech.MechLoadoutController.ActiveLoadout.Systems
    }
  },
  methods:{
    moveSystem(event){
      if(event.moved){
        this.mech.SaveController.save()
      }
    },
  },  
  data: () => {
    return {
      systems: null,
      preventDnd: true
    } 
  },
  created() {
    this.preventDnd = this.isTouch
  },
  mounted() {
      this.systems = this.mech.MechLoadoutController.ActiveLoadout.Systems
  },
})
