























import Vue from 'vue'
import LandingPageMobile from './mobile.vue'
import LandingPageDesktop from './desktop.vue'
import WelcomeMessage from './welcome.vue'

export default Vue.extend({
  name: 'landing-page',
  components: {
    LandingPageMobile,
    LandingPageDesktop,
    WelcomeMessage,
  },
  data: () => ({
    storage: 0,
    showStorageWarning: false,
  }),
  created() {
    this.storage = new Blob(Object.values(localStorage)).size / 1024;
    const usage = this.storage / (5 * 1024);
    console.info(`CC LOCALSTORAGE USAGE: ${this.storage.toFixed(2)} KB of 5MB (${(this.storage / (5 * 1024)).toFixed(2)}%)`);
    if (usage > 0.9) {
      this.showStorageWarning = true
    }
  },
  
})
