







































































































































































































































































































































import Vue from 'vue'
import PrintAction from './components/PrintAction.vue'
import PrintDeployable from './components/PrintDeployable.vue'

export default Vue.extend({
  name: 'mech-print',
  components: { PrintAction, PrintDeployable },
  props: {
    mech: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mounts() {
      return this.mech.MechLoadoutController.ActiveLoadout.AllMounts(
        this.mech.Pilot.has('corebonus', 'cb_improved_armament'),
        this.mech.Pilot.has('corebonus', 'cb_integrated_weapon'),
        this.mech.Pilot.has('CoreBonus', 'cb_superheavy_mounting')
      )
    },
  },
  methods: {
    signed(val: number) {
      return val > -1 ? `+${val}` : `${val}`
    },
  },
})
