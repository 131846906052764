













































import Vue from 'vue'
import { saveFile } from '@/io/Dialog'
import { Pilot } from '@/class'

export default Vue.extend({
  name: 'export-dialog',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    syncing: false,
    copyConfirm: false,
  }),
  methods: {
    show() {
      this.$refs.dialog.show()
    },
    hide() {
      this.$refs.dialog.hide()
    },
    exportPilot() {
      this.pilot.BrewController.SetBrewData()
      saveFile(
        this.pilot.Callsign.toUpperCase().replace(/\W/g, '') + '.json',
        JSON.stringify(Pilot.Serialize(this.pilot)),
        'Save Pilot'
      )
      this.hide()
    },
    copyPilot() {
      this.pilot.BrewController.SetBrewData()
      navigator.clipboard.writeText(JSON.stringify(Pilot.Serialize(this.pilot)))
      Vue.prototype.$notify('Pilot data copied to clipboard')
      this.hide()
    },
    async copyCode() {
      this.copyConfirm = true
      navigator.clipboard.writeText(this.pilot.ShareCode).then(
        function () {
          Vue.prototype.$notify('Cloud ID copied to clipboard.', 'confirmation')
        },
        function () {
          Vue.prototype.$notifyError('Unable to copy Cloud ID')
        }
      )
      setTimeout(() => {
        this.copyConfirm = false
      }, 1200)
    },
  },
})
