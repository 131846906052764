


































































































































































































































































import Vue from 'vue'
import { Pilot, HASE } from '@/class'

export default Vue.extend({
  name: 'cc-mech-skills-selector',
  props: {
    pilot: Pilot,
  },
  watch: {
    'pilot.MechSkillsController.IsMissingHASE': function (newVal) {
      if (newVal === false) window.scrollTo(0, document.body.scrollHeight)
    },
  },
  methods: {
    add(field: HASE) {
      this.pilot.MechSkillsController.MechSkills.Increment(field)
    },
    remove(field: HASE) {
      this.pilot.MechSkillsController.MechSkills.Decrement(field)
    },
    close() {
      this.$emit('close')
    },
  },
})
