



















































































import Vue from 'vue'
import { Reserve } from '@/class'
export default Vue.extend({
  name: 'scrounge-barter',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    skillRoll: '',
    custom_name: '',
    details: '',
    choices: [
      'It was stolen, probably from someone who’s looking for it',
      'It’s degraded, old, filthy, or malfunctioning',
      'Someone else has it right now and won’t give it up without force or convincing',
    ],
    choice: 0,
    trades: ['Time', 'Dignity', 'Reputation', 'Health, comfort and wellness'],
    trade: 0,
  }),
  methods: {
    addReserve() {
      const nr = new Reserve({
        id: 'reserve_scroungebarter',
        type: 'Resources',
        name: 'Asset',
        label: '',
        description: '',
        resource_note: this.details,
        resource_name: this.custom_name,
        resource_cost: '',
        used: false,
        consumable: false,
      })
      if (this.skillRoll < 10) nr.ResourceCost = this.choices[this.choice]
      else if (this.skillRoll < 20)
        nr.ResourceCost = `Acquiring this has cost you your ${this.trades[
          this.trade
        ].toLowerCase()}`
      this.pilot.ReservesController.AddReserve(nr)
      this.close()
    },
    close() {
      this.choice = 0
      this.trade = 0
      this.skillRoll = ''
      this.custom_name = ''
      this.details = ''
      this.$emit('close')
    },
  },
})
