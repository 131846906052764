










































































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { License } from '@/class'

export default Vue.extend({
  name: 'license-select-item',
  props: {
    license: {
      type: Object,
      required: true,
    },
    rank: {
      type: Number,
      required: true,
    },
    isSelectable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    frame(id: string) {
      const compendium = getModule(CompendiumStore, this.$store)
      return compendium.referenceByID('Frames', id)
    },
    prereq(l: License) {
      return l.Prerequisite
    },
  },
})
