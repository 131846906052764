






















































































































































































































































import Vue from 'vue'
import { Project } from '@/class'
export default Vue.extend({
  name: 'get-creative',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    tabs: 0,
    project_name: '',
    details: '',
    complicated: false,
    initialRoll: '',
    improveRoll: '',
    improve: '',
    cost: '',
    improveSelection: null,
    costs: [
      ' Quality materials',
      ' Specific knowledge or techniques',
      ' Specialized tools',
      ' A good workspace',
    ],
  }),
  computed: {
    projects() {
      return this.pilot.ReservesController.Reserves.filter(
        x => x.Type === 'Project' && !x.IsFinished
      )
    },
  },
  methods: {
    addProject() {
      const p = new Project({
        id: 'reserve_project',
        type: 'Project',
        name: 'Project (In Progress)',
        label: this.project_name,
        description: this.details,
        complicated: this.complicated,
        resource_name: this.project_name,
        resource_note: '',
        resource_cost: '',
        used: false,
        can_finish: false,
        finished: false,
        consumable: false,
        progress: this.initialRoll < 10 || this.improveRoll < 10 ? 1 : 0,
        requirements: [],
      })
      if (this.cost) p.ResourceCost = `Requires: ${this.cost.toString()}`
      this.pilot.ReservesController.AddReserve(p)
      this.close()
    },
    improveProject() {
      if (this.cost) this.improveSelection.ResourceCost = `Requires: ${this.cost.toString()}`
      if (this.initialRoll < 10) this.improveSelection.Progress = 1
      this.close()
    },
    completeProject() {
      this.improveSelection.Name = this.improveSelection.ResourceLabel
      this.improveSelection.ResourceName = ''
      this.improveSelection.ResourceCost = ''
      this.improveSelection.IsFinished = true
      this.close()
    },
    close() {
      this.tabs = 0
      this.project_name = ''
      this.details = ''
      this.complicated = false
      this.initialRoll = ''
      this.improveRoll = ''
      this.improve = ''
      this.cost = ''
      this.improveSelection = null
      this.$emit('close')
    },
  },
})
